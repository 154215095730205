import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import WrapperBlock from "@container/WrapperBlock";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import ThumbnailLinkCard from "@elements/Card/Thumbnail/ThumbnailLinkCard";
import VideoPlayer from "@elements/Player/VideoPlayer";
import fetchTMApi from "@helper/fetchTMApi";
import { BC_PLAYER_ID } from "@helper/getEnvVariables";
import { LatestVideoResponse } from "@helper/transformer/useBrightcoveResponse";
import { isArrayWithElements } from "@helper/utils";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSwrApi from "@hooks/useSwrApi";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import VideoDetailMetaData from "@pages/Video/VideoDetailMetaData";
import {
  CustomContext,
  ResponseType,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { VideoPayloadResponseType } from "@typings/PagePayloadType";
import { ReactElement, useEffect, useState } from "react";

export default function VideoDetailPage(): ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<VideoPayloadResponseType, string>
  > = useRouteContext();
  const { data } = useSwrApi<LatestVideoResponse[]>("latest-videos");
  const [offset, setOffset] = useState(10);
  const [videos, setVideos] = useState<LatestVideoResponse[]>();

  useEffect(() => {
    const fetchData = () => {
      if (!data || data.length === 0) return;
      setVideos(data);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  const playing = dataLoaderResponse.payload.currentVideo;

  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
  const sectionName = "videos";
  const { gaData, metaTags } = VideoDetailMetaData({ playing, sectionName });

  const handleLoadMoreVideo = async () => {
    const moreVideos = await fetchTMApi<LatestVideoResponse[]>(
      `latest-videos?offset=${offset}`,
      "GET",
    );
    setVideos((prevVideos) => [...(prevVideos ?? []), ...moreVideos]);
    setOffset((prevSize) => prevSize + 10);

    // This avoids GTM push.
    return 0;
  };

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between pb-5">
        <MainBlock
          sectionName={sectionName}
          pageName={playing.name}
          showTitle={true}
        >
          <div className="flex flex-col lg:flex-row">
            <div className="flex pt-2 flex-col w-full">
              {playing.id && (
                <VideoPlayer
                  playing={playing}
                  headerType="h1"
                  showTitle={false}
                  playerId={BC_PLAYER_ID}
                />
              )}
            </div>
          </div>
        </MainBlock>
        <div className="lg:w-4/12 pl-xs pr-xxs min-h-[800px] overflow-hidden">
          <div
            id="scroll-more-videos"
            data-testid="side-block"
            className="w-full h-[800px] overflow-scroll overflow-x-hidden"
          >
            <div className="w-full pt-sm">
              <span className="font-primary font-bold lg:text-2md leading-7 text-grey-400">
                மேலும் காணொளிகள்
              </span>
              <div className="w-full">
                {isArrayWithElements(videos) &&
                  videos.map((video, index) => (
                    <>
                      <ThumbnailLinkCard<LatestVideoResponse>
                        key={index}
                        variant="video"
                        {...video}
                      />
                      {index == 1 && (
                        <AdsImu2
                          sectionName={sectionName}
                          rootClassName="py-md"
                          pageAdTargetType={pageAdTargetValue}
                        />
                      )}
                    </>
                  ))}
                <LoadMore onLoadMore={handleLoadMoreVideo} />
              </div>
            </div>
          </div>
        </div>
      </WrapperBlock>
    </MainContainer>
  );
}
