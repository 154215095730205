import paywallDesk from "@assets/banner/paywall/PaywallDesktop.webp";
import paywallMob from "@assets/banner/paywall/PaywallMobile.webp";
import { ArticleGaEventsDataProps } from "@elements/ArticleParagraph/ArticleRegwall";
import { Image } from "@elements/ResponsiveImage/Image";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export type PaywallArticleDataProps = {
  articleData: ArticleGaEventsDataProps;
};

export default function Paywall({ articleData }: PaywallArticleDataProps) {
  const { isScreenMD } = useWindowSize();
  const TmCustomGaEvent = useTMCustomEventAnalyticsCall();

  const handlePaywallClick = () => {
    const cookieName = "sph_user_article_count";
    const existingCookie = Cookies.get(cookieName);
    TmCustomGaEvent({
      event: "customEvent",
      eventCategory: "Article Paywall",
      eventAction: "Click",
      abVariant: "article paywall",
      eventLabel: existingCookie,
      ...articleData,
    });
  };

  let image = paywallMob;
  let url =
    "https://subscribe.sph.com.sg/promotions/tmpersonal99cents/?utm_campaign=tmp399&utm_medium=sph-publication&utm_source=tm&utm_content=paywallnov23mobile";
  if (isScreenMD) {
    image = paywallDesk;
    url =
      "https://subscribe.sph.com.sg/promotions/tmpersonal99cents/?utm_campaign=tmp399&utm_medium=sph-publication&utm_source=tm&utm_content=paywallnov23desktop";
  }

  return (
    <div className="relative -top-24 -mb-600">
      <div className="bg-500 h-23">&nbsp;</div>
      <Link
        to={url}
        target="_blank"
        onClick={handlePaywallClick}
        rel="noopener noreferrer"
      >
        <Image
          src={image}
          alt={"Paywall Banner"}
          className="w-full object-cover !aspect-unset"
          srcWidth={100}
          srcHeight={100}
        />
      </Link>
    </div>
  );
}
