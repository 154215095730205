import { ToggleButton } from "@blocks/Home/ToggleButton";
import Image from "@components/ResponsiveImage/Image";
import Heading from "@elements/Typography/Heading";
import { MovieListingMap } from "@endpoints/getMoviesList";
import useSwrApi from "@hooks/useSwrApi";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

const MovieCard = ({
  title,
  url,
  imgUrl,
}: {
  title: string;
  url: string;
  imgUrl: string;
}) => {
  return (
    <a
      className={twMerge(
        "min-w-[120px] h-[242px] lg:min-w-[160px] lg:h-[300px]",
      )}
      href={url}
      target="_blank"
      rel="noreferrer"
    >
      <Image
        displayWidth={160}
        srcWidth={160}
        srcHeight={243}
        src={imgUrl}
        alt={twMerge("Movie: ", title)}
      />
      <p
        className={twMerge(
          "font-regular font-primary text-base lg:text-xs leading-300",
        )}
      >
        {title}
      </p>
    </a>
  );
};

export const MovieTimingsSection = () => {
  const { data: moviesListingMap } =
    useSwrApi<MovieListingMap>(`movies-listing`);
  const moviesList = moviesListingMap
    ? Object.keys(moviesListingMap).map(
        (mapKey: string) => moviesListingMap[mapKey],
      )
    : [];
  const [currentMovieIndex, setCurrentMovieIndex] = useState(0);
  const { width } = useWindowDimensions();
  const [scrollAmount, setScrollAmount] = useState(0);
  const isMobile = width <= 480;

  const onNextMovie = () => {
    const index = Math.min(currentMovieIndex + 4, moviesList.length - 1);
    if (index === moviesList.length - 1) return;
    setCurrentMovieIndex(index);
    const scrollContainer = document.getElementById("movies-scroll-container");
    if (scrollContainer) {
      const scrollMax = scrollContainer.clientWidth;
      const newScroll = scrollAmount + 680;
      setScrollAmount(newScroll);
      scrollContainer.scrollTo({
        top: 0,
        left: Math.max(newScroll, scrollMax),
        behavior: "smooth",
      });
    }
  };

  const onPreviousMovie = () => {
    const scrollMin = 0;
    const index = Math.max(currentMovieIndex - 4, 0);
    setCurrentMovieIndex(index);
    const scrollContainer = document.getElementById("movies-scroll-container");
    if (scrollContainer) {
      const newScroll = scrollAmount - 680;
      setScrollAmount(newScroll);
      scrollContainer.scrollTo({
        top: 0,
        left: Math.max(newScroll, scrollMin),
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="border-b border-pink-300">
      <div className="lg:my-md">
        <Heading
          Element="h2"
          className="lg:hidden text-gray-100 text-md font-bold hover:underline mb-5"
        >
          திரைப்பட நேரங்கள்
        </Heading>
        <div className="hidden lg:flex justify-between mb-md">
          <Heading Element="h2" className="text-gray-100 text-2.5md font-bold">
            திரைப்பட நேரங்கள்
          </Heading>
          <div className="flex gap-2.5 lg:pr-lg">
            <ToggleButton
              isPrevious
              isDisabled={currentMovieIndex === 0}
              onClick={onPreviousMovie}
            />
            <ToggleButton
              isDisabled={currentMovieIndex + 3 === moviesList.length}
              onClick={onNextMovie}
            />
          </div>
        </div>

        <div
          id="movies-scroll-container"
          className={twMerge(
            "flex overflow-y-hidden gap-sm lg:gap-2.5",
            isMobile ? "overflow-x-scroll" : "overflow-x-hidden",
          )}
        >
          {moviesList.map((movie, index) => (
            <MovieCard
              key={index}
              title={movie.title}
              url={movie.gv_url}
              imgUrl={movie.film_poster}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
