type FooterCopyrightProps = {
  testId?: string;
  subPublication?: string;
};

export function FooterCopyright({
  testId,
  subPublication = "",
}: FooterCopyrightProps) {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div
      data-testid={`${testId}-copyright`}
      className="not-italic text-2sm tracking-normal leading-400 text-gray-100"
    >
      {subPublication == "balar-murasu" ? (
        <>
          MCI (P) 001/04/2024. SPH Digital News Copyright © {year}. SPH Media
          Limited. Co. Regn. No. 202120748H. All Rights Reserved.
        </>
      ) : (
        <>
          MCI (P) 002/04/2024. SPH Digital News Copyright © {year}. SPH Media
          Limited. Co. Regn. No. 202120748H. All Rights Reserved.
        </>
      )}
    </div>
  );
}
