/**
 * Company: SPHMedia
 * Description: [MySPH Login button component]
 */

import UserMenuLogo from "@assets/user/Login.svg";
import { mySPHOpenLogin } from "@helper/mySPH";
import { ReactElement } from "react";

/**
 * MySphLoginButton
 * Description: [return mysph login button]
 * @param props - The props required by the component (if any)
 * @returns The Login Button
 */
export default function MySphLoginButton(): ReactElement {
  return (
    <button
      onClick={() => {
        mySPHOpenLogin();
      }}
      className="ml-1 lg:ml-0 lg:px-3.5 lg:pt-2 cursor-pointer"
    >
      <div className="inline-flex text-black font-bold text-md lg:text-xxs text-black-200 lg:text-black-100 leading-6 lg:leading-4">
        <img
          width={17}
          height={20}
          src={UserMenuLogo}
          className="mr-3 lg:mr-2 h-6.5 lg:h-5"
          alt="login"
        />
        உள் நுழைய
      </div>
    </button>
  );
}
