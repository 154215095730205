import BreadCrumb from "@elements/BreadCrumb/BreadCrumb";
import Heading from "@elements/Typography/Heading";
import getPageDetails from "@helper/getPageDetails";
import { ReactElement, ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type MainBlockProps = Readonly<{
  children: ReactNode;
  className?: string;
  testId?: string;
  showTitle?: boolean;
  showBreadcrumbs?: boolean;
  sectionName?: string;
  pageName?: string;
}>;

export default function MainBlock({
  children,
  className,
  pageName,
  sectionName,
  testId = "main-block",
  showTitle = true,
  showBreadcrumbs = true,
}: MainBlockProps): ReactElement {
  const { label, url } = getPageDetails(sectionName) || { label: sectionName };

  let breadcrumbItems = [];
  if (pageName) {
    breadcrumbItems = [
      { breadcrumbLabel: "Home", breadcrumbUrl: "/" },
      { breadcrumbLabel: label, breadcrumbUrl: "/" + url },
      { breadcrumbLabel: pageName },
    ];
  } else {
    breadcrumbItems = [
      { breadcrumbLabel: "Home", breadcrumbUrl: "/" },
      { breadcrumbLabel: label },
    ];
  }

  return (
    <div
      className={twMerge(
        "w-full lg:w-8/12 lg:border-r lg:border-pink-300 lg:pr-md",
        className,
      )}
      data-testid={testId}
    >
      {showBreadcrumbs && <BreadCrumb items={breadcrumbItems} />}
      {showTitle && (
        <Heading className="text-3md lg:text-lg font-bold font-primary">
          {pageName ? pageName : label}
        </Heading>
      )}
      {children}
    </div>
  );
}
