import MostRead from "@container/SideBlock/MostRead";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import Divider from "@elements/Divider/Divider";
import { Image } from "@elements/ResponsiveImage/Image";
import Heading from "@elements/Typography/Heading";
import { PageAdTargetValue } from "@typings/Ads.d";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type SideBlockProps = {
  className?: string;
  sectionName?: string;
  pageAdTargetValue: PageAdTargetValue;
  hideAdForMobile?: boolean;
  gsChannels?: string[];
};

export default function NdSideBlock({
  className,
  sectionName,
  pageAdTargetValue,
  hideAdForMobile = false,
  gsChannels,
}: SideBlockProps) {
  return (
    <div
      className={twMerge(
        "lg:w-4/12 pl-xs pr-xxs min-h-[800px] max-[768px]:pl-0 max-[768px]:pr-0",
      )}
    >
      <div data-testid="side-block" className={twMerge("w-full", className)}>
        {hideAdForMobile && (
          <AdsImu2
            sectionName={sectionName}
            rootClassName="py-md"
            pageAdTargetType={pageAdTargetValue}
            gsChannels={gsChannels}
          />
        )}
        <div className="w-full min-h-[400px]">
          <>
            <Heading
              Element="h2"
              className="text-md font-bold mt-5 mb-2 text-red-100 leading-600"
            >
              சிவப்பின் ஒவ்வொரு சாயலிலும் சிங்கப்பூரின் கதை
            </Heading>
            <div className="border-bottom native-banner pb-100">
              <Link
                reloadDocument
                to="https://nationalday.sph.com.sg/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  objectFit="contain"
                  src={
                    "https://static1.tamilmurasu.com.sg/s3fs-public/2024-08/national-day.png"
                  }
                  className="w-full mx-auto my-2.5 border-0"
                  srcWidth={360}
                  srcHeight={404}
                  alt="NDP SPH Media"
                  dataTestId="ndp-image"
                />
              </Link>
            </div>
            <Divider className="mt-5" />
          </>
        </div>
        <MostRead />
      </div>
    </div>
  );
}
