import ApplyParagraphAnnotations from "@elements/ArticleParagraph/ApplyParagraphAnnotations";
import { ProcessedTextElement } from "@typings/Opensearch";
import { twMerge } from "tailwind-merge";

type PullQuoteCardProps = {
  quote: ProcessedTextElement;
  quotee: ProcessedTextElement;
  quoteLabel: ProcessedTextElement | undefined;
  quoteCredit: ProcessedTextElement | undefined;
};

export default function PullQuoteCard({
  quote,
  quotee,
  quoteLabel,
  quoteCredit,
}: PullQuoteCardProps) {
  const flexCol = "flex flex-col";

  return (
    <div
      className={twMerge(
        "gap-2 lg:gap-3 p-4 mb-4 bg-gray-500 font-sans",
        flexCol,
      )}
    >
      {quoteLabel && quoteLabel.value && (
        <div
          className={twMerge("font-normal text-md text-pink-600 leading-1000")}
        >
          {ApplyParagraphAnnotations(quoteLabel.value, quoteLabel.annotations)}
        </div>
      )}
      <div
        className={twMerge(
          "gap-1 md:gap-2 font-normal text-md leading-normal text-black-400",
          flexCol,
        )}
      >
        {ApplyParagraphAnnotations(quote.value, quote.annotations)}
      </div>
      <div
        className={twMerge(
          "text-xs leading-[17px] font-semibold text-pink-600",
          flexCol,
        )}
      >
        <span>
          {ApplyParagraphAnnotations(quotee.value, quotee.annotations)}
        </span>
        {quoteCredit && quoteCredit.value && (
          <span>
            {ApplyParagraphAnnotations(
              quoteCredit.value,
              quoteCredit.annotations,
            )}
          </span>
        )}
      </div>
    </div>
  );
}
