import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { HeaderMenuChildrens } from "@header/HeaderMenuConstants";
import cx from "classnames";
import { Link } from "react-router-dom";

type MobileSubMenuItemsProps = {
  submenus: HeaderMenuChildrens[];
  dropdown: boolean;
  testId: string;
  type: string;
};

export function SubMenuDropdown({
  submenus,
  dropdown,
  testId,
  type,
}: MobileSubMenuItemsProps) {
  return (
    <List
      testId={`${testId}-${type}-sublist`}
      className={cx(
        "-mx-xxs px-1.5 lg:px-2",
        !dropdown && "hidden",
        dropdown &&
          "block lg:inline-flex lg:absolute lg:left-0 lg:w-full lg:justify-center lg:mt-2 lg:z-10 lg:bg-white-100 lg:shadow-6xl lg:rounded lg:p-2",
      )}
    >
      {submenus.map((submenu, index) => (
        <ListItem
          key={index}
          testId={`${testId}-${type}-${index}`}
          className="first:pt-0 pt-sm lg:pt-0"
        >
          <Link
            reloadDocument
            to={submenu.url}
            target={submenu.newTab ? "_blank" : "_self"}
            rel="noreferrer"
            className="block font-bold text-md tracking-tighter lg:text-base lg:py-2 lg:px-3"
          >
            {submenu.title}
          </Link>
        </ListItem>
      ))}
    </List>
  );
}
