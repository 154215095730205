import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import { SectionMetaDataProps } from "@pages/Section/SectionMetaData";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export function BalarMurasuMetaData({
  sectionName,
  sectionTamilName,
}: SectionMetaDataProps): PageMetaDataValues {
  const level2 = sectionName.replace("-", " ");
  const capitalizeSectionName = capitalize(level2);

  const metaTitle = `${sectionTamilName} இதழ்கள் - ${capitalizeSectionName} | Tamil Murasu`;
  const metaDescription = `Tamil Preschool videos, Tamil Children's digest, Tamil Language content for kids & Primary tamil students`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: sectionName,
    keywords: [
      "பாலர் முரசு, Balar Murasu",
      "தமிழ் குழந்தைகள் இதழ், தமிழ் குழந்தைகள் காணொளிகள்",
      "Tamil Preschool digest, Tamil worksheets, Kids Videos, Singapore, India",
    ],
  };

  const gaData: GADataProps = {
    level2: level2,
    title: `${sectionName.replace("-", "_")}_index`,
    contentcat: 1,
    contenttype: undefined,
  };

  return {
    gaData,
    metaTags,
  };
}
