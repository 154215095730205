import { isClientSide } from "@helper/utils";

export type SendPermutiveIdentifyProps = {
  per_userid: string;
  ptid: string;
  per_suid: string;
};

export function sendPermutiveIdentify({
  per_userid,
  ptid,
  per_suid,
}: SendPermutiveIdentifyProps) {
  if (!isClientSide) return;

  /* eslint-disable @typescript-eslint/no-unsafe-call */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  if (
    typeof window.permutive !== "undefined" &&
    typeof window.permutive.identify === "function"
  ) {
    window.permutive.identify([
      {
        id: per_userid,
        tag: "userid",
        priority: 0,
      },
      {
        id: ptid,
        tag: "ptid",
        priority: 1,
      },
      {
        id: per_suid,
        tag: "suid",
        priority: 2,
      },
    ]);
  } else {
    console.error(
      "Permutive is not loaded or identify function is not available",
    );
  }
}
