import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@components/Carousel/ArticleCarousel.css";

import enabledArrow from "@assets/actions/enable-carousel-button.svg";
import ArticleImage from "@components/Carousel/ArticleImage";
import { ImageGalleryCarousel } from "@typings/Opensearch";
import { useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

export type CarouselPropType = {
  renderItems: ImageGalleryCarousel;
  sectionName?: string;
};

export default function ArticleCarousel({
  renderItems,
  sectionName,
}: CarouselPropType) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = renderItems.length;

  // eslint-disable-next-line
  const SliderComponent: typeof Slider =
    // eslint-disable-next-line
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;

  const sliderRef = useRef<Slider>(null);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Need to do this as workaround for known issue, https://github.com/akiran/react-slick/issues/1130
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, currentSlide, slideCount, ...others } = props;
    return <span {...others}>{children}</span>;
  };

  const settings: Settings = {
    infinite: true,
    swipe: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: (
      <SlickButtonFix>
        <button onClick={next}>
          <img src={enabledArrow} alt="next-arrow" width={40} height={40} />
        </button>
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <button onClick={previous}>
          <img
            src={enabledArrow}
            className="-scale-x-100"
            alt="prev-arrow"
            width={40}
            height={40}
          />
        </button>
      </SlickButtonFix>
    ),
    afterChange: (current: number) => {
      setCurrentSlide(current);
    },
  };

  return (
    <>
      {renderItems.length === 1 ? (
        <ArticleImage
          url={renderItems[0].image?.url}
          alt={renderItems[0].id}
          sectionName={sectionName}
          caption={renderItems[0].caption}
          credit={renderItems[0].credit}
        />
      ) : (
        <div className="article-carousel relative max-w-[825px] h-auto">
          <SliderComponent ref={sliderRef} {...settings}>
            {renderItems.map((item) => (
              <ArticleImage
                key={item.id}
                index={item.id}
                alt={item.id}
                url={item.image?.url}
                caption={item.caption}
                credit={item.credit}
                currentSlideCount={currentSlide}
                totalSlideCount={totalSlides}
              />
            ))}
          </SliderComponent>
        </div>
      )}
    </>
  );
}
