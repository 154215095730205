import { SearchInput } from "@components/Header/Search/SearchInput";
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import NoResult from "@pages/Search/NoResult";
import SearchMetaData from "@pages/Search/SearchMetaData";
import SearchResultList from "@pages/Search/SearchResultList";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import {
  SearchErrorResponseType,
  SearchPayloadResponseType,
} from "@typings/PagePayloadType";
import { ReactElement } from "react";

export function SearchResultPage(): ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<SearchPayloadResponseType, SearchErrorResponseType>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const searchedText: string = dataLoaderResponse.payload.searchedText;
  const resultCount: number = dataLoaderResponse.payload.resultCount;
  const keywordArticles = dataLoaderResponse.payload.data;

  const sectionName = "search";

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const { gaData, metaTags } = SearchMetaData(searchedText);

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock sectionName={sectionName} showTitle={false}>
          <SearchInput expanded={true} initSearchValue={searchedText} />
          {keywordArticles.length === 0 ? (
            <NoResult searchedText={searchedText} />
          ) : (
            <SearchResultList
              articles={keywordArticles}
              searchedText={searchedText}
              sectionName={sectionName}
              pageAdTargetValue={pageAdTargetValue}
              resultCount={resultCount}
            />
          )}
        </MainBlock>

        <SideBlock
          sectionName={sectionName}
          className="w-full lg:block"
          pageAdTargetValue={pageAdTargetValue}
          hideAdForMobile={true}
        />
      </WrapperBlock>
    </MainContainer>
  );
}
