import { HoroscopeSection } from "@blocks/Home/HoroscopeSection";
import { JobsListing } from "@blocks/Home/JobsListing";
import { MovieTimingsSection } from "@blocks/Home/MovieTimingsSection";
import PodcastPlayer from "@blocks/LatestPodcasts/PodcastPlayer";
import LatestVideoPlayer from "@blocks/LatestVideo/LatestVideoPlayer";
import { ForexSection } from "@container/SideBlock/ForexSection";
import { GoldSection } from "@container/SideBlock/GoldSection";
import MostRead from "@container/SideBlock/MostRead";
import { CompanionAdsImu2 } from "@elements/Advertisement/variant/CompanionAdImu";
import { ArticleGaEventsDataProps } from "@elements/ArticleParagraph/ArticleRegwall";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import debounce from "@helper/debounce";
import { BC_PLAYER_ID_SINGLE_PLAYER } from "@helper/getEnvVariables";
import { ProcessedArticleData } from "@helper/transformer/useOsResponse";
import { formatDate } from "@helper/useDateFormat";
import { getWindowOrigin, isClientSide } from "@helper/utils";
import { isElementInViewPort } from "@helper/visibilityHelpers";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import { PageAdTargetValue } from "@typings/Ads";
import cx from "classnames";
import { useCallback, useEffect, useState } from "react";

export type RenderArticleBlocksProps = {
  pageNumber: number;
  pageUrl: string;
  hasOutbrain?: boolean;
  articleData: ProcessedArticleData;
  pageAdTargetValue: PageAdTargetValue;
  gsChannels?: string[];
};

const blockConfig = [
  "latestPortraitVideo",
  "latestLandscapeVideo",
  "latestPodcast",
  "mostReadNews",
  "Outbrain",
  "Horoscope",
  "Movies",
  "Gold",
  "Currency",
  "Jobs",
];

const blockGaEventCategoryName = [
  "recent videos",
  "recent videos",
  "podcast",
  "most-read stories",
  "outbrain",
  "horoscope",
  "movies",
  "gold widget",
  "currency",
  "jobs",
];

export default function RenderArticleBlocks({
  pageNumber,
  pageUrl,
  hasOutbrain = true,
  articleData,
  pageAdTargetValue,
  gsChannels,
}: RenderArticleBlocksProps) {
  const TmCustomGaEvent = useTMCustomEventAnalyticsCall();
  const origin = getWindowOrigin();

  const tmCustomGaEventsProps = {
    eventLabel: articleData.title,
    articleid: articleData.id,
    cue_articleid: articleData.id,
    contenttype: "1",
    chapter1: "",
    level2: articleData.sectionName,
    pubdate: formatDate(articleData.dateCreated, "yyyy-MM-dd HH:mm:ss"),
    title: articleData.title,
    url: origin + pageUrl,
    keyword: articleData.tags
      ? articleData.tags.map((tags) => tags.name).join(", ")
      : "",
    pagination: pageNumber,
  } as ArticleGaEventsDataProps;

  const [portraitVideoOffset, setPortraitVideoOffset] = useState<number>(0);
  const [landscapeVideoOffset, setLandscapeVideoOffset] = useState<number>(0);
  const [blockIndex, setBlockIndex] = useState<number>(0);
  const [hasBeenViewed, setHasBeenViewed] = useState(false);

  const handleScroll = useCallback(() => {
    if (!isClientSide || hasBeenViewed) return;
    const articleBlockData = document.querySelectorAll(
      `.block-${blockConfig[blockIndex]}`,
    );
    articleBlockData.forEach((article) => {
      if (isElementInViewPort(article, 0)) {
        TmCustomGaEvent({
          event: "customEvent",
          eventCategory: blockGaEventCategoryName[blockIndex],
          eventAction: "visible",
          ...tmCustomGaEventsProps,
        });

        // Mark the block as viewed
        setHasBeenViewed(true);
      } else {
        // Mark the block as not viewed
        setHasBeenViewed(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockIndex, hasBeenViewed]);

  useEffect(() => {
    if (!isClientSide) return;
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  // Update blockIndex when pageNumber changes.
  useEffect(() => {
    const newBlockIndex = pageNumber % blockConfig.length;
    setBlockIndex(newBlockIndex);

    if (newBlockIndex == 0 && pageNumber > 0) {
      setPortraitVideoOffset(portraitVideoOffset + 1);
    }
    if (newBlockIndex == 1 && pageNumber > 1) {
      setLandscapeVideoOffset(landscapeVideoOffset + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const handleOnBlockClick = () => {
    TmCustomGaEvent({
      event: "customEvent",
      eventCategory: blockGaEventCategoryName[blockIndex],
      eventAction: "click",
      ...tmCustomGaEventsProps,
    });
  };

  const renderBlock = (blockName: string) => {
    switch (blockName) {
      case "latestPortraitVideo":
        return (
          <LatestVideoPlayer
            offset={portraitVideoOffset}
            type={"PORTRAITS"}
            className={"aspect-portrait"}
            playerId={BC_PLAYER_ID_SINGLE_PLAYER}
          />
        );
      case "latestLandscapeVideo":
        return (
          <LatestVideoPlayer
            offset={landscapeVideoOffset}
            type={"LANDSCAPE"}
            className={"aspect-landscape"}
            playerId={BC_PLAYER_ID_SINGLE_PLAYER}
          />
        );
      case "latestPodcast":
        return <PodcastPlayer />;
      case "mostReadNews":
        return <MostRead />;
      case "Outbrain":
        return (
          hasOutbrain && (
            <div className="pt-5 border-t border-pink-300">
              <Outbrain pathName="/" version="SB_1" />
            </div>
          )
        );
      case "Horoscope":
        return <HoroscopeSection />;
      case "Movies":
        return <MovieTimingsSection />;
      case "Gold":
        return <GoldSection />;
      case "Currency":
        return <ForexSection />;
      case "Jobs":
        return <JobsListing />;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        onClick={handleOnBlockClick}
        aria-hidden="true"
        className={`block-${blockConfig[blockIndex]} lg:w-4/12 lg:min-h-[800px]`}
      >
        {/* Render blocks based on blockIndex */}
        {renderBlock(blockConfig[blockIndex])}
      </div>

      {blockConfig[blockIndex] !== "Outbrain" && pageNumber !== 0 && (
        <div
          className={cx(
            "border-y border-solid border-pink-300 mt-5 lg:border-none justify-center flex",
            {
              "!border-t-0 !border-t-none !mt-0": [
                "mostReadNews",
                "Horoscope",
                "Movies",
                "Jobs",
              ].includes(blockConfig[blockIndex]),
            },
          )}
        >
          <CompanionAdsImu2
            uniqueSectionName={articleData.sectionName}
            pageAdTargetType={pageAdTargetValue}
            pageNumber={pageNumber}
            rootClassName={"lg:absolute lg:top-0 lg:bottom-1/4 lg:!h-auto"}
            gsChannels={gsChannels}
            articleSlotTarget={[
              { key: "page_number", value: (pageNumber + 1).toString() },
              {
                key: "tmarticleid",
                value: articleData.id,
              },
              {
                key: "tmtags",
                value:
                  articleData.tags?.map((tags) => tags.name).join(", ") || "",
              },
            ]}
          />
        </div>
      )}
    </>
  );
}
