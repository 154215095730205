import ArticleCard from "@components/Card/ArticleCard";
import { Imu1CompanionAdsInfiniteScroll } from "@elements/Advertisement/variant/AdsCompanion";
import fetchTMApi from "@helper/fetchTMApi";
import { ProcessedArticleData } from "@helper/transformer/useOsResponse";
import { displayAfter, hideAfter } from "@helper/utils";
import {
  CreateLoadMoreHandler,
  CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@hooks/useLoadMore/SetFirstRenderedArticleIds";
import { PageAdTargetValue } from "@typings/Ads";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import { useState } from "react";
import React from "react";

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

type SearchResultListProps = {
  articles: ProcessedArticleData[];
  searchedText: string;
  sectionName: string;
  pageAdTargetValue: PageAdTargetValue;
  resultCount: number;
};

export default function SearchResultList({
  articles,
  searchedText,
  sectionName,
  pageAdTargetValue,
  resultCount = 0,
}: SearchResultListProps) {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean = articles.length == INITIAL_ARTICLE_COUNT;
  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(articles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? articles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: searchedText,
      sort: lastLoadedArticle && lastLoadedArticle.sort,
    };

    const fetchMoreSearchedStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const response: ProcessedArticleData[] = await fetchTMApi(
        "more-search-stories",
        "POST",
        {
          searchedText: requestData.filterArticlesBasedOn,
          sort: requestData.sort,
          size: 5,
        },
      );

      return response;
    };
    const createLoadMoreHandlerProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreSearchedStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      lastLoadedArticle,
      loadMoreData,
      renderedArticleIds,
      pageSize: PAGE_SIZE,
      pageNumber,
    };

    const response = await CreateLoadMoreHandler(createLoadMoreHandlerProps);
    return response;
  };

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col gap-8">
          <div className="bg-red-400 my-2">
            <div className="p-2 flex items-center flex-col">
              <div className="font-regular italic text-black-200 text-2sm lg:text-[30px] mb-2">
                {searchedText}
              </div>
            </div>
          </div>
        </div>
        <div className="pb-1 border-b border-gray-2200">
          சுமார் {resultCount} முடிவுகள்.
        </div>
        {[...articles, ...loadMoreData].map((context, index) => (
          <React.Fragment key={index}>
            <ArticleCard
              displayWidth={260}
              displayHeight={192}
              elements={{ hasDate: true }}
              {...context}
              overrideStyle={{
                cardWrapper:
                  (displayAfter(index + 1, PAGE_SIZE)
                    ? "border-none"
                    : "flex-col sm:flex-row") +
                  (hideAfter(index, PAGE_SIZE)
                    ? " sm:flex-row flex-row-reverse"
                    : ""),
                titleStyle: hideAfter(index, PAGE_SIZE)
                  ? "font-semibold text-2sm text-gray-100 hover:text-gray-100 sm:text-3md sm:text-red-100 sm:hover:text-red-200"
                  : "text-md sm:text-3md",
                paragraphStyle: hideAfter(index, PAGE_SIZE)
                  ? "hidden sm:block"
                  : "",
                imageWrapperStyle: hideAfter(index, PAGE_SIZE)
                  ? "w-[90px] h-[90px] sm:h-auto"
                  : "",
                imageStyle: hideAfter(index, PAGE_SIZE)
                  ? "h-[90px] sm:h-auto"
                  : "",
              }}
            />
            {displayAfter(index + 1, PAGE_SIZE) && (
              <Imu1CompanionAdsInfiniteScroll
                sectionName={sectionName}
                index={(index + 1) / PAGE_SIZE}
                pageAdTargetType={pageAdTargetValue}
              />
            )}
          </React.Fragment>
        ))}
        {showLoadMore ? (
          <LoadMore
            rootClassName="my-4"
            onLoadMore={handleLoadMore}
            hasMore={hasMoreStories}
            loadText="Lagi Cerita"
          />
        ) : null}
      </div>
    </>
  );
}
