import { CAS_SCRIPT } from "@helper/getEnvVariables";
import useScript from "@hooks/useScript";

export default function useCasScript() {
  const { isScriptReady } = useScript({
    id: "cas-script",
    src: CAS_SCRIPT,
    checkScriptIsLoaded: () => {
      return (
        typeof window.SPH_CDP === "function" &&
        typeof window._data !== "undefined"
      );
    },
  });

  return { isScriptReady };
}
