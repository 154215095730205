import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export type SectionMetaDataProps = {
  sectionName: string;
  sectionTamilName: string;
};

export function SectionMetaData({
  sectionName,
  sectionTamilName,
}: SectionMetaDataProps): PageMetaDataValues {
  const level2 = sectionName.replace("-", " ");
  const capitalizeSectionName = capitalize(level2);

  let metaTitle = `${sectionTamilName}, ${sectionTamilName} செய்திகள் - ${capitalizeSectionName} news | Tamil Murasu`;
  let metaDescription = `${capitalizeSectionName} news | Tamil Murasu - Get Latest Singapore, Tamilnadu, India, World & Sports News in Tamil. சிங்கப்பூரின் ஒரே தமிழ் மொழி செய்தித்தாள். சிங்கப்பூர் செய்திகள், இந்தியா செய்திகள், தமிழ்நாடு செய்திகள், விளையாட்டு செய்திகள்.`;

  if (sectionName === "maanavar-murasu") {
    metaTitle = `${sectionTamilName}, ${sectionTamilName} - ${capitalizeSectionName} | Tamil Murasu`;
    metaDescription = `${capitalizeSectionName} | Tamil Murasu - Get Latest Singapore, Tamilnadu, India, World & Sports News in Tamil. சிங்கப்பூரின் ஒரே தமிழ் மொழி செய்தித்தாள். சிங்கப்பூர் செய்திகள், இந்தியா செய்திகள், தமிழ்நாடு செய்திகள், விளையாட்டு செய்திகள்.`;
  }

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: sectionName,
  };

  const gaData: GADataProps = {
    level2: level2,
    title: `${sectionName.replace("-", "_")}_index`,
    contentcat: 1,
    contenttype: undefined,
  };

  return {
    gaData,
    metaTags,
  };
}
