import antiflick from "@app/providers/scripts/antiflick";
import comscore from "@app/providers/scripts/comscore";
import {
  permutiveScript,
  permutiveSphl,
} from "@app/providers/scripts/permutive";
import VWOAsyncSmartCode from "@app/providers/scripts/VWOAsyncSmartCode";
import { fetchEnvelope, useLiveRamp } from "@hooks/useLiveRamp";
import useScript from "@hooks/useScript";
import useScriptFunction from "@hooks/useScriptFunction";

export default function HeadScriptProvider(): null {
  // Echobox script
  useScript({
    id: "ebx",
    src: "https://applets.ebxcdn.com/ebx.js",
    async: true,
  });

  // Outbrain script
  useScript({
    id: "article-outbrain",
    src: "https://widgets.outbrain.com/outbrain.js",
    async: true,
  });

  // Edge Permutive App
  useScript({
    id: "edge-permutive-app",
    src: permutiveScript,
    type: "text/javascript",
  });

  //Permutive SPHL
  useScriptFunction({
    id: "permutive-sphl",
    value: permutiveSphl,
  });

  // Anti flick script
  useScriptFunction({
    id: "anti-flick",
    value: antiflick,
  });

  // VWO script
  useScriptFunction({
    id: "VWOA-async-smart-code",
    value: VWOAsyncSmartCode,
  });

  // ComScore script
  useScriptFunction({
    id: "comscore",
    value: comscore,
  });

  // Live Implementation
  useLiveRamp({ fetchEnvelope });

  return null;
}
