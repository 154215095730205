import fetcher from "@helper/fetcher";
import { getWindowOrigin } from "@helper/utils";
import useSWR, { SWRResponse } from "swr";

export default function useSwrApi<T>(endpoint: string): SWRResponse<T> {
  const swrRes: SWRResponse<T> = useSWR<T>(
    `${getWindowOrigin()}/_plat/api/v1/${endpoint}`,
    fetcher,
  );
  return swrRes;
}
