import ArticleCard from "@components/Card/ArticleCard";
import AuthorCard from "@components/Card/AuthorCard";
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import { Imu1CompanionAdsInfiniteScroll } from "@elements/Advertisement/variant/AdsCompanion";
import fetchTMApi from "@helper/fetchTMApi";
import { displayAfter, hideAfter } from "@helper/utils";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@hooks/useLoadMore/SetFirstRenderedArticleIds";
import { AuthorMetaData } from "@pages/Author/AuthorMetaData";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import { AuthorPayloadResponseType } from "@typings/PagePayloadType";
import React, { Fragment, useState } from "react";

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

export default function AuthorPage(): React.ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<AuthorPayloadResponseType, string>
  > = useRouteContext();
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const articles = dataLoaderResponse.payload.data;
  const author = articles[0].authors && articles[0].authors[0];
  const authorName = articles[0].authors && articles[0].authors[0].name;

  const authorAlias = dataLoaderResponse.payload.authorAlias;

  const showLoadMore: boolean = articles.length == INITIAL_ARTICLE_COUNT;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSetFirstRenderedArticleIds(articles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? articles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: authorAlias,
      sort: lastLoadedArticle?.sort,
    };

    const fetchMoreAuthorStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const response: ProcessedArticleData[] = await fetchTMApi(
        "more-author-stories",
        "POST",
        {
          authorUrlAlias: requestData.filterArticlesBasedOn,
          sort: requestData.sort,
        },
      );

      return response;
    };
    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreAuthorStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;
  const sectionName = "author";

  const { gaData, metaTags } = AuthorMetaData({
    authorName,
    authorAlias,
  });
  // Hiding the title for Author's listing page.
  const showTitle = false;

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock sectionName={authorName} showTitle={showTitle}>
          {author && (
            <AuthorCard
              author={author}
              displayWidth={150}
              displayHeight={150}
            />
          )}
          {[...articles, ...loadMoreData].map((section, index) => (
            <Fragment key={section.id}>
              <ArticleCard
                displayWidth={260}
                displayHeight={192}
                elements={{ hasDate: true }}
                {...section}
                overrideStyle={{
                  cardWrapper:
                    (displayAfter(index + 1, PAGE_SIZE)
                      ? "border-none"
                      : "flex-col lg:flex-row") +
                    (hideAfter(index, PAGE_SIZE)
                      ? " lg:flex-row flex-row-reverse"
                      : ""),
                  titleStyle: hideAfter(index, PAGE_SIZE)
                    ? "font-semibold text-2sm text-gray-100 hover:text-gray-100 lg:text-2lg lg:text-red-100 lg:hover:text-red-200"
                    : "text-md lg:text-2lg",
                  paragraphStyle: hideAfter(index, PAGE_SIZE)
                    ? "hidden lg:block"
                    : "",
                  imageWrapperStyle: hideAfter(index, PAGE_SIZE)
                    ? "w-[90px] h-[90px] lg:h-auto"
                    : "",
                  imageStyle: hideAfter(index, PAGE_SIZE)
                    ? "h-[90px] lg:h-auto"
                    : "",
                }}
              />
              {displayAfter(index + 1, PAGE_SIZE) && (
                <Imu1CompanionAdsInfiniteScroll
                  sectionName={sectionName}
                  index={(index + 1) / PAGE_SIZE}
                  pageAdTargetType={pageAdTargetValue}
                />
              )}
            </Fragment>
          ))}
          {showLoadMore ? (
            <div className="flex w-full justify-center mb-xl">
              <LoadMore
                rootClassName="my-4 w-full"
                onLoadMore={handleLoadMore}
                hasMore={hasMoreStories}
                loadText="Load More"
                mobileInfiniteScroll={true}
              />
            </div>
          ) : null}
        </MainBlock>

        <SideBlock
          sectionName="author"
          className="w-full lg:block"
          pageAdTargetValue={pageAdTargetValue}
          hideAdForMobile={true}
        />
      </WrapperBlock>
    </MainContainer>
  );
}
