import { NEWSLETTER_COOKIE_MOBILE } from "@components/Blocks/NewsletterSignupForm/constants";
import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import BmCard from "@components/Card/BmCard";
import BmMainContainer from "@container/BmMainContainer";
import WrapperBlock from "@container/WrapperBlock";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import Heading from "@elements/Typography/Heading";
import { BC_BM_PLAYER_ID } from "@helper/getEnvVariables";
import { LatestVideoResponse } from "@helper/transformer/useBrightcoveResponse";
import { BalarMurasuMetaData } from "@pages/BalarMurasu/BalarMurasuMetaData";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  ResponseType,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { BmPayloadResponseType } from "@typings/PagePayloadType";
import Cookies from "js-cookie";
import { Fragment, ReactElement, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function BalarMurasu(): ReactElement {
  const [searchParams] = useSearchParams();
  const mobwebview = searchParams.get("mobwebview");

  if (mobwebview && mobwebview == "true") {
    Cookies.set(NEWSLETTER_COOKIE_MOBILE, "true", {
      path: "/",
      SameSite: "None",
      Secure: true,
    });
  } else {
    Cookies.set(NEWSLETTER_COOKIE_MOBILE, "false", {
      path: "/",
      expires: new Date(0), // Thu, 01 Jan 1970 00:00:00 UTC
    });
  }

  const routeContext: CustomContext<
    TRouteWithoutRedirect<BmPayloadResponseType, string>
  > = useRouteContext();

  const containerRef = useRef<HTMLDivElement>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  const bmIssues = dataLoaderResponse.payload.issues;
  const [firstIssue, ...archieves] = bmIssues;

  const videosList = dataLoaderResponse.payload.videos;

  const handleOnEndedVideo = () => {
    const index = currentVideoIndex + 1;
    if (index === videosList.length - 1) {
      setCurrentVideoIndex(0);
    } else {
      setCurrentVideoIndex(index);
    }
  };

  const handleOnClickThumbnail = (index: number) => () => {
    setCurrentVideoIndex(index);
    containerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const sectionName = "balar-murasu";
  const sectionTamilName = "பாலர் முரசு";
  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const mobwebviewCookie = Cookies.get(NEWSLETTER_COOKIE_MOBILE);
  const mobwebviewBool = mobwebviewCookie == "true";

  const { gaData, metaTags } = BalarMurasuMetaData({
    sectionName,
    sectionTamilName,
  });

  return (
    <BmMainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      mobwebview={mobwebviewBool}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="">
        <div className="border-b border-solid border-b-white-100 pb-2 mb-3">
          <div className="flex flex-wrap gap-1">
            <div className="flex-[59%] text-left overflow-hidden max-lg:flex-100">
              <Heading
                Element="h3"
                className="text-pink-600 text-3md font-bold leading-10 pb-2 mb-2 max-lg:text-2md max-md:text-sm"
              >
                காணொளிகள்
              </Heading>
              {videosList[currentVideoIndex]?.id && (
                <BrightcoveVideo
                  videoId={videosList[currentVideoIndex].id}
                  forwardedRef={containerRef}
                  onEnded={handleOnEndedVideo}
                  nextPlaylist={handleOnEndedVideo}
                  playerId={BC_BM_PLAYER_ID}
                />
              )}

              <div className="bg-gray-150 lg:mt-1 p-2 pr-0">
                <div
                  id="scroll-container"
                  className={twMerge(
                    "flex overflow-y-hidden overflow-x-scroll gap-2.5",
                  )}
                >
                  {videosList.map((video, index) => (
                    <ThumbnailCard<LatestVideoResponse>
                      key={index}
                      handleOnClickThumbnail={handleOnClickThumbnail(index)}
                      variant="video"
                      {...video}
                      nowPlaying={currentVideoIndex === index}
                      customCardStyles="flex-col w-50 h-49 lg:py-1 max-sm:w-31.75 max-sm:h-34"
                      isDark
                      videoPosterDimensions="h-31.5 w-55 max-sm:h-31.5 max-sm:w-31.75"
                      isHideDateTime
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-[40%] text-center">
              <BmCard {...firstIssue} showHeadline={true} />
            </div>
          </div>
        </div>

        <Heading
          Element="h3"
          className="text-pink-600 text-2md max-lg:text-2md max-md:text-sm font-bold leading-6 mb-3 mt-3 py-2"
        >
          மற்ற பதிப்புகளைக் காண்க
        </Heading>
        <div className="flex flex-wrap gap-3 justify-normal max-lg:justify-center">
          {[...archieves].map((issue) => (
            <Fragment key={issue.id}>
              <BmCard {...issue} />
            </Fragment>
          ))}
        </div>
      </WrapperBlock>
    </BmMainContainer>
  );
}
