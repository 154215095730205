import Image from "@components/ResponsiveImage/Image";
import Heading from "@elements/Typography/Heading";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export function NdHeader(): ReactElement {
  return (
    <>
      <div className="ndp-section-title bg-pink-200 lg:h-15 h-13 lg:mt-xm lg:mb-500 lg:mx-auto mt-200 mb-sm mx-[calc(-50vw+50%)]">
        <div className="inline-flex">
          <Image
            srcWidth={120}
            srcHeight={120}
            src="https://static1.tamilmurasu.com.sg/media/ndp-59-icon.png?w=120&amp;dpr=1.0"
            objectFit="contain"
            alt="தேசிய தினம் 2024"
            className="lg:w-30 w-23 absolute lg:-mt-400 lg:ml-md -mt-300 ml-xs"
          />
          <div className=" py-xxs pr-md lg:pl-900 pl-800">
            <Link reloadDocument to="/keywords/national-day-2024">
              <Heading
                Element="h1"
                className={twMerge(
                  "font-bold font-primary lg:text-1xl leading-700 mt-xxs bg-gradient-to-r from-white-100 to-yellow-100 bg-clip-text text-transparent text-3md",
                )}
              >
                தேசிய தினம் 2024
              </Heading>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
