import TmLogo from "@assets/tm_logo.webp";
import TmMobileLogo from "@assets/tm_logo_mobile.svg";
import { HeaderMmLogo } from "@header/ManavarMurasu/HeaderMmLogo";
import cx from "classnames";
export type MmProps = {
  testId: string;
  sectionName?: string;
};

export function HeaderLogo({ testId, sectionName }: MmProps) {
  return (
    <div className="inline-flex m-auto lg:col-start-2 lg:col-span-4 lg:col-end-6 lg:h-20 xs:h-lg">
      <a
        data-testid={`${testId}-logo`}
        href="/"
        rel="home"
        title="Tamil Murasu"
      >
        <img
          width={297.5}
          height={68}
          alt="Home"
          src={TmLogo}
          className={cx(
            "hidden lg:block",
            sectionName === "maanavar-murasu" ? "h-13 mt-3" : "h-17 mb-2 mt-2",
          )}
        />
        <img
          width={144}
          height={29.81}
          alt="Home"
          src={TmMobileLogo}
          className="lg:hidden block w-36 mr-1"
        />
      </a>

      {sectionName === "maanavar-murasu" && <HeaderMmLogo />}
    </div>
  );
}
