import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getErrorMessage } from "@helper/getErrorMessage";
import { useCallback, useEffect, useState } from "react";

interface LoadMoreProps {
  rootClassName?: string;
  onLoadMore: () => Promise<number>;
  hasMore?: boolean;
  loadText?: string;
}

/**
 *
 * @param onLoadMore the action will be performed when user click the load more button, from the design point of view
 * the load more component should not "understand" or "care" about the action content
 * @param hasMore this boolean indicator from parent logic to tell the component to hide itself or not
 * @returns
 */
export default function LoadMore({
  onLoadMore,
  hasMore = true,
}: LoadMoreProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [, setError] = useState<string>("");
  const [scrollLoadingInProgress, setScrollLoadingInProgress] = useState(false);
  const sendDataToGTM = useGTMDispatch();
  const dataToGTMOnInfiniteScroll = useCallback(
    (eventLabelVal: string, landingPageVal: string) => {
      sendDataToGTM({
        event: "infinite_scroll",
        eventCategory: "Infinite Scroll",
        eventAction: "1",
        eventLabel: eventLabelVal,
        landingPage: landingPageVal,
      });
    },
    [sendDataToGTM],
  );

  const handleLoadMore = useCallback(() => {
    setIsLoading(true);

    Promise.resolve(onLoadMore())
      .then((response) => {
        if (response > 0 && typeof window !== "undefined") {
          const eventLabelVal = window.location.href;
          const landingPageVal =
            window.location.origin + window.location.pathname;
          dataToGTMOnInfiniteScroll(eventLabelVal, landingPageVal);
        }
      })
      .catch((err: unknown) => {
        setError(getErrorMessage(err));
      })
      .finally(() => {
        setIsLoading(false);
        setScrollLoadingInProgress(false);
      });
  }, [onLoadMore, dataToGTMOnInfiniteScroll]);

  useEffect(() => {
    const el = document.getElementById("scroll-more-videos");

    function handleScroll() {
      const scrollTop = el?.scrollTop || 0;
      const scrollHeight = el?.scrollHeight || 0;
      const clientHeight = el?.clientHeight || 0;

      if (
        scrollTop + clientHeight >= scrollHeight - 300 &&
        !scrollLoadingInProgress &&
        hasMore
      ) {
        setScrollLoadingInProgress(true);
        handleLoadMore();
      }
    }

    el?.addEventListener("scroll", handleScroll);

    return () => {
      el?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollLoadingInProgress, hasMore, handleLoadMore]);

  return <>{isLoading ? <LoadingGif isLoading={isLoading} /> : ""}</>;
}

export function LoadingGif({ isLoading }: { isLoading: boolean }) {
  return isLoading ? (
    <div data-testid="loading-text" className="block">
      <p className="text-red-100 text-medium">• Show more</p>
    </div>
  ) : null;
}
