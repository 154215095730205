import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export type NewsletterMetaDataProps = {
  sectionName: string;
};

export function NewsletterMetaData({
  sectionName,
}: NewsletterMetaDataProps): PageMetaDataValues {
  const level2 = sectionName.replace("-", " ");

  const metaTitle = "Newsletter Signup | Tamil Murasu";
  const metaDescription = "";

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: sectionName,
  };

  const gaData: GADataProps = {
    level2: level2,
    title: `${sectionName.replace("-", "_")}_index`,
    contentcat: 1,
    contenttype: undefined,
  };

  return {
    gaData,
    metaTags,
  };
}
