import "@elements/AiSummary/AiSummary.css";

import aiSparkle from "@assets/actions/Ai_sparkle.svg";
import downArrow from "@assets/actions/down-arrow.svg";
import { ArticleGaEventsDataProps } from "@elements/ArticleParagraph/ArticleRegwall";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export type aiSummaryProps = {
  englishHeadline: string;
  englishSummary: string;
  customGaEvents: ArticleGaEventsDataProps;
};

export default function AiSummary({
  englishHeadline,
  englishSummary,
  customGaEvents,
}: aiSummaryProps) {
  const TmCustomGaEvent = useTMCustomEventAnalyticsCall();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { isScreenLG } = useWindowSize();
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);

    if (isOpen && !isScreenLG) {
      setIsActive(false);
    }

    TmCustomGaEvent({
      event: "customEvent",
      eventCategory: "ai summary",
      eventAction: isOpen ? "collapse" : "expand",
      ...customGaEvents,
    });
  };

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        !isScreenLG &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsActive(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        ref={dropdownRef}
        className={twMerge(
          "ai-summary mb-5 border border-transparent border-solid rounded-200",
          isOpen && "border-0",
        )}
      >
        <button
          onClick={toggleDropdown}
          className={twMerge(
            "dropdown-button block h-xl w-full rounded-200 relative z-10",
            isOpen && isScreenLG && "opened rounded-bl-none rounded-br-none",
            isOpen &&
              !isScreenLG &&
              "dropdown-opened rounded-bl-none rounded-br-none",
            isOpen &&
              isActive &&
              "dropdown-active rounded-bl-none rounded-br-none",
            !isOpen && "dropdown-closed",
          )}
        >
          <div className="block px-3 py-0.5">
            <span className="flex float-left pl-1 pr-2 font-primary font-normal text-xs leading-6">
              <img
                src={aiSparkle}
                alt={"ai-logo"}
                width={20}
                height={20}
                className="mr-0.5"
              />
              AI
            </span>
            <span className="font-primary float-none lg:float-left font-semibold text-gray-100 text-base ml-0 lg:ml-3">
              SUMMARISE IN ENGLISH
            </span>
            <img
              src={downArrow}
              alt={"down-arrow"}
              width={12}
              height={12}
              className={twMerge(
                "float-right m-2 inline duration-500",
                isOpen && "-rotate-180",
              )}
            />
          </div>
        </button>
        <div
          className={twMerge(
            "dropdown font-primary text-3sm leading-400 text-gray-100 transition-height ease border border-transparent border-solid rounded-bl-200 rounded-br-200 box-content duration-500 transition-all",
            !isOpen
              ? "h-0 opacity-0 overflow-hidden mb-0"
              : "pt-4 pb-5 px-3 h-auto opacity-100",
          )}
        >
          <div className="font-bold mb-5">{englishHeadline}</div>
          <div className="font-normal mb-5">{englishSummary}</div>
          <span className="text-grey-700 font-normal text-xs leading-5">
            Generated by AI
          </span>
        </div>
      </div>
    </>
  );
}
