import "@container/BmStyles.css";

import { pubPageAdTargeting } from "@elements/Advertisement/AdsConstants";
import CatfishAd from "@elements/Advertisement/CatfishAd";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import PrestitialAd from "@elements/Advertisement/PrestitialAd";
import GAData, { GADataProps } from "@elements/GAData/GAData";
import MetaTags, { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { BmFooter } from "@footer/BmFooter";
import { BmHeader } from "@header/BalarMurasu/BmHeader";
import { HeaderBmLogo } from "@header/BalarMurasu/HeaderBmLogo";
import { useNeuronPageViewPush } from "@hooks/useAnalyticsCall/useNeuronPageViewPush";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import useOKTAUserInfo from "@hooks/useOktaUserInfo";
import useScript from "@hooks/useScript";
import useSendPermutiveIdentify from "@hooks/useSendPermutiveIdentify";
import { AdTargetingType, PageAdTargetValue } from "@typings/Ads";
import { ReactElement, ReactNode } from "react";

export type AdsSettings = {
  isPrestitialEnabled: boolean | undefined;
  isCatfishEnabled: boolean | undefined;
  isOutbrainEnabled: boolean | undefined;
};

export type BmMainProps = Readonly<{
  testId?: string;
  children: ReactNode;
  gaData?: GADataProps;
  metaTagsProps?: MetaTagsProps;
  sectionName?: string;
  mobwebview?: boolean;
  pageAdTargetValue: PageAdTargetValue;
}>;

/*
 * Container to provide a fixed width and horizontal padding around the content. Typically paired
 * with a Layout component to create reusable templates across all pages. Width of child content as
 * follows with each screen size:

  Viewport width >= 1280: Children component width: 1150px
  Viewport width >= 1024: Children component width: 990px
  Viewport width >= 992: Children component width: 960px
  Viewport width >= 768: Children component width: 720px
  Viewport width >= 576: Children component width: 540px
  All other viewport widths: Children component width: 100% - padding 30px

 * @param {ReactElement} children - Any React Element but likely used to wrap a Tamil Murasu module
 * @param {String} testId - Unique string used to target elements when testing
 * @returns {ReactElement} - A wrapped module with a fixed width that resizes on view port resize
 */

export default function BmMainContainer({
  children,
  testId = "main-container-bm",
  gaData,
  metaTagsProps,
  sectionName,
  mobwebview = false,
  pageAdTargetValue,
}: BmMainProps): ReactElement {
  useOKTAUserInfo();
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX as string,
  });
  useNeuronPageViewPush();
  useSendPermutiveIdentify();

  const adSettings: AdsSettings = useGlobalAdSetting();

  const pageAdTarget = pubPageAdTargeting[pageAdTargetValue] as AdTargetingType<
    string,
    PageAdTargetValue
  >;

  return (
    <>
      <div data-testid="bm-wrapper" className="w-full mx-auto bm-wrapper">
        {!mobwebview && <BmHeader testId="bm-header" />}
        <div className="grid content-around bg-white-100 lg:hidden shadow-inner mb-2">
          <div className="inline-flex items-center justify-center h-21">
            <HeaderBmLogo showMobile={true} />
          </div>
        </div>

        <div className="bm-header-shadow mb-4"></div>
        <div
          data-testid={testId}
          className="w-full mx-auto xl:max-w-xl xxs:px-sm lg:p-0 max-xsm:px-1"
        >
          <PrestitialAd
            slot={GoogleAdsSlotFactory.prestitial(sectionName)}
            isPrestitialEnabled={adSettings.isPrestitialEnabled}
            pubAdTargeting={[pageAdTarget]}
          />
          {gaData && <GAData {...gaData} />}
          {metaTagsProps && <MetaTags {...metaTagsProps} />}

          {children}
        </div>

        {!mobwebview && <BmFooter />}
        <CatfishAd
          slot={GoogleAdsSlotFactory.catfish(sectionName)}
          isCatfishEnabled={adSettings.isCatfishEnabled}
          pubAdTargeting={[pageAdTarget]}
        />
      </div>
    </>
  );
}
