import emailCopyBg from "@assets/translation/email_copy_bg.svg";
import emailCopySm from "@assets/translation/email_copy_sm.svg";
import getQuoteBg from "@assets/translation/get_quote_bg.svg";
import getQuoteSm from "@assets/translation/get_quote_sm.svg";
import getTranslationBg from "@assets/translation/get_translation_bg.svg";
import getTranslationSm from "@assets/translation/get_translation_sm.svg";
import Image from "@components/ResponsiveImage/Image";

export default function TranslationSteps() {
  return (
    <div className="block lg:flex lg:w-[77%] mx-6 lg:mx-auto">
      <div className="w-full lg:w-[31%] lg:flex lg:justify-center">
        <Image
          srcWidth={0}
          srcHeight={0}
          objectFit="contain"
          src={emailCopyBg}
          alt={"email_copy"}
          className="hidden lg:block"
        />
        <Image
          srcWidth={0}
          srcHeight={0}
          objectFit="contain"
          src={emailCopySm}
          alt={"email_copy"}
          className="block lg:hidden mb-5 h-[140px] float-left"
        />
      </div>
      <div className="lg:w-[37%] lg:flex lg:justify-center lg:border-x border-solid border-gray-1100">
        <div className="lg:px-7">
          <Image
            srcWidth={0}
            srcHeight={0}
            objectFit="contain"
            src={getQuoteBg}
            alt={"get_quote"}
            className="hidden lg:block"
          />
          <Image
            srcWidth={0}
            srcHeight={0}
            objectFit="contain"
            src={getQuoteSm}
            alt={"get_quote"}
            className="block lg:hidden mb-5 h-[123px]"
          />
        </div>
      </div>
      <div className="w-full lg:w-[31%] lg:flex lg:justify-center">
        <Image
          srcWidth={0}
          srcHeight={0}
          objectFit="contain"
          src={getTranslationBg}
          alt={"get_translation"}
          className="hidden lg:block"
        />
        <Image
          srcWidth={0}
          srcHeight={0}
          objectFit="contain"
          src={getTranslationSm}
          alt={"get_translation"}
          className="block lg:hidden mb-5 h-36"
        />
      </div>
    </div>
  );
}
