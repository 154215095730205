import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { LatestVideoResponse } from "@helper/transformer/useBrightcoveResponse";
import { formatDate } from "@helper/useDateFormat";
import type { PageMetaDataValues } from "@typings/PageMetaData";

type VideoMetaDataProps = {
  playing: LatestVideoResponse;
  sectionName: string;
};

export default function VideoDetailMetaData({
  playing,
  sectionName,
}: VideoMetaDataProps): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: sectionName,
    title: playing.name,
    keyword: sectionName,
    contentcat: 1,
    contenttype: "3",
    pubdate: formatDate(playing.published_date, "yyyy-MM-dd HH:mm:ss"),
  };
  const pageUrl = `videos/${playing.id}`;

  const metaTags: MetaTagsProps = {
    title: `${playing.name} - காணொளிகள் | Tamil Murasu`,
    description:
      "காணொளிகள் | Watch latest Tamil News Videos: Singapore, India, Politics, Entertainment & Sports Updates. தமிழ் செய்தி காணொளிகள்: சிங்கப்பூர், இந்தியா, அரசியல், பொழுதுபோக்கு & விளையாட்டு செய்திகள்.",
    slug: pageUrl,
    image: playing.thumbnail,
    keywords: [
      "தமிழ் காணொளிகள்",
      "இன்றைய காணொளிகள்",
      "தமிழ் வீடியோக்கள்",
      "Latest Tamil Videos",
      "Latest News Videos",
      "Videos",
    ],
  };

  return {
    gaData,
    metaTags,
  };
}
