/* eslint-disable react-hooks/rules-of-hooks */
import HoroscopeBlock from "@blocks/Horoscope/HoroscopeBlock";
import Image from "@components/ResponsiveImage/Image";
import MainBlock from "@container/MainBlock";
import OutbrainMainContainer, {
  AdsSettings,
} from "@container/OutbrainMainContainer";
import SideBlock from "@container/SideBlock/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import ApplyParagraphAnnotations from "@elements/ArticleParagraph/ApplyParagraphAnnotations";
import Divider from "@elements/Divider/Divider";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import { isArrayWithElements } from "@helper/utils";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import ErrorPage from "@pages/ErrorPage";
import { HoroscopeMetaData } from "@pages/Horoscope/HoroscopeMetaData";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  ResponseType,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { HoroscopePayloadResponseType } from "@typings/PagePayloadType";
import { Fragment } from "react/jsx-runtime";
import { useParams } from "react-router-dom";

export default function Horoscope() {
  const { zodiac } = useParams();

  const routeContext: CustomContext<
    TRouteWithoutRedirect<HoroscopePayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const adSettings: AdsSettings = useGlobalAdSetting();

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message={dataLoaderResponse.payload ?? ""} />;
  }

  const horoscope = dataLoaderResponse.payload;
  const thisZodiac = horoscope.zodiac;
  const thisZodiacImg = thisZodiac ? thisZodiac.img : "";
  const thisZodiacEn = thisZodiac ? thisZodiac.en : "";
  const thisZodiacTa = thisZodiac ? thisZodiac.ta : "";

  const horoscopeDaily = horoscope.daily && horoscope.daily.articleBody;
  const horoscopeWeekly = horoscope.weekly && horoscope.weekly.articleBody;

  const sectionName = "horoscope";
  const sectionTamilName = thisZodiac ? thisZodiac.ta : "";

  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;

  const { gaData, metaTags } = HoroscopeMetaData({
    sectionName,
    sectionTamilName,
  });

  return (
    <OutbrainMainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
      suppliedAdsSettings={adSettings}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock sectionName={`${sectionName}-${zodiac}`} showTitle={false}>
          <div className="pb-5">
            <h3 className="w-full font-bold text-1lg mt-0 mb-2 leading-600">
              ராசிபலன்
            </h3>
            <div className="flex flex-wrap -mx-2.5">
              <div className="relative w-full pr-200 pl-6 text-center sm:flex-33 sm:max-w-33">
                <div className="w-32 h-32 mx-auto items-center bg-gradient-to-b from-[#E8E8EF] to-[#F3F3F7] rounded-50 flex justify-center overflow-hidden">
                  <Image
                    srcWidth={150}
                    srcHeight={150}
                    src={thisZodiacImg}
                    alt={thisZodiacEn}
                    className="inline"
                    objectFit="fill"
                  />
                </div>
                <h1 className="text-center mt-0 mb-2 leading-600 text-xl font-bold text-black-200">
                  {thisZodiacTa}
                </h1>
              </div>
              <div className="relative w-full px-6 sm:flex-66 sm:max-w-66">
                <div>
                  {isArrayWithElements(horoscopeDaily) && (
                    <Fragment>
                      <p className="mt-0 mb-4 text-xs font-normal leading-800 text-gray-100">
                        <strong>இன்றைய பலன்:</strong>
                      </p>
                      {horoscopeDaily.map((element, i: number) => {
                        return (
                          element.type == "paragraph" && (
                            <p
                              key={i}
                              className="mt-0 mb-4 text-xs font-normal leading-800 text-gray-100"
                            >
                              {ApplyParagraphAnnotations(
                                element.value,
                                element.annotations,
                              )}
                            </p>
                          )
                        );
                      })}
                    </Fragment>
                  )}
                  {isArrayWithElements(horoscopeWeekly) && (
                    <Fragment>
                      <p className="mt-0 mb-4 text-xs font-normal leading-800 text-gray-100">
                        <strong>வாரப் பலன்:</strong>
                      </p>
                      {horoscopeWeekly.map((element, i: number) => {
                        return (
                          element.type == "paragraph" && (
                            <p
                              key={i}
                              className="mt-0 mb-4 text-xs font-normal leading-800 text-gray-100"
                            >
                              {ApplyParagraphAnnotations(
                                element.value,
                                element.annotations,
                              )}
                            </p>
                          )
                        );
                      })}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <Divider className="my-5 border-b border-gray-2200" />
            <HoroscopeBlock />
            <Divider className="mt-5 border-b border-gray-2200" />
          </div>
          {adSettings.isOutbrainEnabled && (
            <Outbrain
              pathName={`${sectionName}/${thisZodiacEn}`}
              version="AR_1"
            />
          )}
        </MainBlock>
        <SideBlock
          sectionName={sectionName}
          className="w-full lg:block"
          pageAdTargetValue={pageAdTargetValue}
        />
      </WrapperBlock>
    </OutbrainMainContainer>
  );
}
