import regwallPointer from "@assets/banner/regwall/regwall_pointer.svg";
import { mySPHOpenLogin, mySPHOpenSignUp } from "@helper/mySPH";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import Cookies from "js-cookie";

export type ArticleGaEventsDataProps = {
  eventLabel?: string;
  articleid: string;
  contenttype: string;
  chapter1: string;
  level2?: string;
  pubdate: string;
  title: string;
  url: string;
  keyword: string;
  byline?: string;
  cue_articleid?: string;
};

export type regwallArticleDataProps = {
  articleData: ArticleGaEventsDataProps;
};
export default function Regwall({ articleData }: regwallArticleDataProps) {
  const TmCustomGaEvent = useTMCustomEventAnalyticsCall();

  const handleLoginClick = () => {
    mySPHOpenLogin();

    const cookieName = "sph_user_article_count";
    const existingCookie = Cookies.get(cookieName);

    TmCustomGaEvent({
      event: "customEvent",
      eventCategory: "Article Hard Regwall",
      eventAction: "Login",
      abVariant: "hard regwall",
      eventLabel: existingCookie,
      ...articleData,
    });
  };

  const handleSignupClick = () => {
    mySPHOpenSignUp();

    const cookieName = "sph_user_article_count";
    const existingCookie = Cookies.get(cookieName);
    TmCustomGaEvent({
      event: "customEvent",
      eventCategory: "Article Hard Regwall",
      eventAction: "Signup",
      abVariant: "hard regwall",
      eventLabel: existingCookie,
      ...articleData,
    });
  };
  return (
    <div className="relative -top-16 -mb-16 md:-top-12 md:-mb-12 bg-300 px-2.5 py-5">
      <div className="md:w-9/12 m-auto">
        <button
          className="gap-1 cursor-pointer pt-2"
          onClick={handleSignupClick}
        >
          <div className="flex justify-center">
            <img
              width={86}
              height={86}
              src={regwallPointer}
              alt="regwall-pointer"
            />
          </div>
          <div className="flex flex-col md:flex-row justify-center text-center gap-1">
            <span className="font-primary text-black-500 text-2md md:text-2xl font-medium p-0 md:px-xxm py-200">
              <p className="mb-4">Register and read for free!</p>
              உங்கள் செய்தி வரம்பை எட்டிவிட்டீர்கள். மேலும் படிக்க இலவசக் கணக்கு
              தொடங்கவும்.
            </span>
          </div>
        </button>
        <div>
          <button
            onClick={handleSignupClick}
            className="block text-2sm m-auto font-semibold text-white-100 bg-400 h-xl w-full md:w-100 rounded cursor-pointer"
          >
            இப்போது பதிவு செய்யவும்
          </button>
          <div className="text-center font-primary text-xs font-normal mt-2.5">
            <span className="mr-2">ஏற்கெனவே பதிவுசெய்துள்ளீர்களா?</span>
            <button
              onClick={handleLoginClick}
              className="text-pink-700 cursor-pointer"
            >
              இங்கு உள்நுழையலாம்.
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
