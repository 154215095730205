import { ENVIRONMENT } from "@helper/getEnvVariables";

const DEVELOPMENT_ENV = "dev";
const STAGING_ENV = "stg";
const PRODUCTION_ENV = "prd";

export const getZodiacId = (
  devId: string,
  stgId: string,
  prdId: string,
): string => {
  const zodiacIdMap: { [key: string]: string } = {
    [DEVELOPMENT_ENV]: devId,
    [STAGING_ENV]: stgId,
    [PRODUCTION_ENV]: prdId,
  };
  return zodiacIdMap[ENVIRONMENT] || devId;
};
