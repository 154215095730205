import DefaultImage from "@assets/tm_default_image.webp";
import Image from "@components/ResponsiveImage/Image";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { formatDateAgo } from "@helper/useDateFormat";
import { type RawImageField } from "@typings/Opensearch";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type ArticleCardElementProps = {
  hasParagraph?: boolean;
  hasDate?: boolean;
  hasDateInAgo?: boolean;
};

export type ArticleCardStyleProps = {
  cardWrapper?: string;
  contentWrapper?: string;
  paragraphStyle?: string;
  titleWrapper?: string;
  titleStyle?: string;
  imageWrapperStyle?: string;
  imageStyle?: string;
};

export type ArticleCardProps = {
  linkTo: string;
  title: string;
  paragraph?: string;
  fullDate?: string;
  time?: string;
  displayWidth: number;
  displayHeight: number;
  imageField?: RawImageField;
  imageAlt?: string;
  urlPath?: string;
  overrideStyle?: ArticleCardStyleProps;
  elements?: ArticleCardElementProps;
  dateEdited?: string;
};

export default function ArticleCard({
  title,
  paragraph,
  fullDate,
  time,
  displayWidth,
  displayHeight,
  imageField,
  imageAlt,
  linkTo,
  overrideStyle,
  elements,
  dateEdited,
}: ArticleCardProps) {
  const {
    cardWrapper,
    contentWrapper,
    imageWrapperStyle,
    imageStyle,
    paragraphStyle,
    titleStyle,
    titleWrapper,
  } = overrideStyle || {};
  const { hasParagraph = true, hasDate, hasDateInAgo = false } = elements || {};

  return (
    <div
      className={twMerge(
        "flex w-full gap-5 py-5 border-b border-gray-2250",
        cardWrapper,
      )}
    >
      <Link
        reloadDocument
        to={linkTo}
        className={twMerge(
          "w-full flex-shrink-0 md:flex-grow-0 sm:w-[260px]",
          imageWrapperStyle,
        )}
      >
        <Image
          displayWidth={displayWidth}
          srcWidth={displayWidth}
          srcHeight={displayHeight}
          src={imageField?.url ?? DefaultImage}
          alt={imageAlt ?? title}
          className={twMerge("w-full", imageStyle)}
        />
      </Link>

      <div className={twMerge("flex flex-col w-full gap-xs", contentWrapper)}>
        <div className={twMerge("flex flex-col gap-sm", titleWrapper)}>
          <Link reloadDocument to={linkTo}>
            <Heading
              Element="h3"
              className={twMerge(
                "font-bold font-primary hover:underline hover:text-red-200 text-red-100",
                titleStyle,
              )}
            >
              {title}
            </Heading>
          </Link>
        </div>

        {hasParagraph && (
          <p
            className={twMerge(
              "font-regular font-primary text-base lg:text-2sm leading-300",
              paragraphStyle,
            )}
          >
            {paragraph}
          </p>
        )}

        {hasDate && (
          <Date>
            {fullDate}
            {time ? <span className="lowercase"> - {time}</span> : ""}
          </Date>
        )}
        {hasDateInAgo && dateEdited && (
          <Date>
            <span className="lowercase">{formatDateAgo(dateEdited)}</span>
          </Date>
        )}
      </div>
    </div>
  );
}
