import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function HomeMetaData(): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: "home",
    title: "home_index",
    contentcat: 1,
    contenttype: undefined,
  };

  const metaTags: MetaTagsProps = {
    title: "Latest Tamil News, தமிழ் செய்திகள் - Tamil Murasu, தமிழ் முரசு",
    description:
      "Get Latest Singapore, Tamil Nadu, India, World & Sports News in Tamil. சிங்கப்பூரின் ஒரே தமிழ் மொழி செய்தித்தாள். சிங்கப்பூர், இந்தியா, தமிழ்நாடு & விளையாட்டு செய்திகள்.",
    slug: "home",
    keywords: [
      "Tamil News",
      "Latest Singapore, Tamilnadu, India, World, Sports, & Cinema News online, Horoscope, Astrology, Movies",
      "சிங்கப்பூர் செய்திகள், இந்தியா செய்திகள், தமிழ்நாடு செய்திகள், விளையாட்டு செய்திகள், திரைச்செய்தி, ராசிபலன்",
    ],
  };

  return {
    gaData,
    metaTags,
  };
}
