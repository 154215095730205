import shareMobileLogo from "@assets/social_media/share-mb.svg";
import ShareSocial, {
  type SocialVariant,
} from "@elements/ShareSocial/ShareSocial";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";

export type ShareSocialProps = {
  socialIconList: string[];
  pagePath: string;
  deskClassName?: string;
  mbPopupClassName?: string;
  mbClassName?: string;
};

export default function ShareSocialButton({
  socialIconList,
  pagePath,
  deskClassName,
  mbPopupClassName,
  mbClassName,
}: ShareSocialProps) {
  const { isScreenLG } = useWindowSize();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Close the dropdown if clicked outside
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isScreenLG ? (
        <div className={cx("flex gap-5", deskClassName)}>
          {socialIconList.map((variant, index) => (
            <ShareSocial
              variant={variant as SocialVariant}
              pagePath={pagePath}
              key={index}
            />
          ))}
        </div>
      ) : (
        <div ref={dropdownRef} className={mbClassName}>
          <button
            onClick={toggleDropdown}
            className="mb-5 dropdown-button py-2 px-3 flex rounded-3xl w-24 bg-pink-500"
          >
            <img
              src={shareMobileLogo}
              alt={"share-mb"}
              width={24}
              height={24}
              className="mr-1.5"
            />
            <span className="font-primary font-semibold text-xs text-white-100">
              SHARE
            </span>
          </button>
          {isOpen && (
            <div
              className={cx(
                "flex gap-5 bg-white-100 z-10 rounded-3xl mr-sm",
                mbPopupClassName,
              )}
            >
              {socialIconList.map((variant, index) => (
                <ShareSocial
                  variant={variant as SocialVariant}
                  pagePath={pagePath}
                  key={index}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}
