import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import ShareSocial, {
  type SocialVariant,
} from "@elements/ShareSocial/ShareSocial";
import Heading, { HeadingElements } from "@elements/Typography/Heading";
import { BC_PLAYER_ID } from "@helper/getEnvVariables";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { RefObject } from "react";

type Props = {
  playing: LatestVideoResponse;
  forwadedRef?: RefObject<HTMLDivElement>;
  onEnded?: () => void;
  nextPlaylist?: () => void;
  headerType?: HeadingElements;
  showTitle?: boolean;
  playerId?: string;
};

const socialIconList = ["whatsapp", "facebook", "x", "copylink"];

export default function VideoPlayer({
  playing,
  forwadedRef,
  onEnded,
  nextPlaylist,
  headerType = "h3",
  showTitle = true,
  playerId = BC_PLAYER_ID,
}: Props) {
  const { id, name, topStoryDate, formattedTime, description } = playing;
  return (
    <>
      <BrightcoveVideo
        videoId={id}
        forwardedRef={forwadedRef}
        onEnded={onEnded}
        nextPlaylist={nextPlaylist}
        playerId={playerId}
      />
      <div className="flex-col hidden lg:block">
        {showTitle && (
          <Heading
            Element={headerType}
            className="font-primary font-bold text-lg leading-900 text-black-300 mb-5 pt-5"
          >
            {name}
          </Heading>
        )}
        <div className="mb-[20px]">{description}</div>
        <div className="flex items-center justify-between mb-[20px]">
          <div>
            <span className="text-xxs text-semimedium lg:text-[16px] text-grey-900">
              {topStoryDate}
            </span>
            <br />
            <span className="text-xxs text-semimedium lg:text-[14px] text-gray-150">
              {" "}
              {formattedTime} mins
            </span>
          </div>
          <div className="flex gap-5">
            {socialIconList.map((variant, index) => (
              <ShareSocial
                variant={variant as SocialVariant}
                pagePath={`/videos/` + id}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
