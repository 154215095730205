import { ZODIACS } from "@blocks/Horoscope/constants";
import Image from "@components/ResponsiveImage/Image";
import Heading from "@elements/Typography/Heading";
import { Link } from "react-router-dom";

export default function HoroscopeBlock() {
  return (
    <div>
      <Heading Element="h2" className="pb-2.5 text-2.5md font-bold leading-650">
        ராசிபலன்
      </Heading>
      <div className="flex flex-wrap gap-4 max-md:gap-x-14 max-sm:gap-2">
        {ZODIACS.map((zodiac, i: number) => (
          <div
            key={i}
            className="flex-25 lg:flex-[14.67%] md:flex-[19%] max-sm:flex-[25%] text-white text-center"
          >
            <Link reloadDocument to={`/horoscope/${zodiac.en}`}>
              <div className="rounded-50 flex bg-gradient-to-b from-[#E8E8EF] to-[#F3F3F7] hover:bg-gradient-to-l hover:from-[#FAAC43] hover:to-[#F16095] p-0.5">
                <div className="h-full w-full bg-gradient-to-b from-[#E8E8EF] to-[#F3F3F7] rounded-50 p-200">
                  <Image
                    srcWidth={100}
                    srcHeight={100}
                    src={zodiac.img}
                    alt={zodiac.en}
                    className="inline max-lg:py-4 max-md:py-0"
                    objectFit="fill"
                  />
                </div>
              </div>
              <h6 className="text-2sm font-bold leading-450 text-gray-100">
                {zodiac.ta}
              </h6>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
