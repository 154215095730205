import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@components/Carousel/RelatedArticleCarousel.css";

import enabledArrow from "@assets/actions/enable-carousel-button.svg";
import ArticleCard from "@components/Card/ArticleCard";
import Heading from "@elements/Typography/Heading";
import { ProcessedArticleData } from "@helper/transformer/useOsResponse";
import cx from "classnames";
import { useRef } from "react";
import Slider, { Settings } from "react-slick";

export type RelatedArticleCarouselProps = {
  testId?: string;
  rootClassName?: string;
  relatedArticles: ProcessedArticleData[];
};

export default function RelatedArticleCarousel({
  testId,
  rootClassName,
  relatedArticles,
}: RelatedArticleCarouselProps): React.ReactElement {
  // eslint-disable-next-line
  const SliderComponent: typeof Slider =
    // eslint-disable-next-line
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;

  const sliderRef = useRef<Slider>(null);
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const previous = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  // Need to do this as workaroudn known issue, https://github.com/akiran/react-slick/issues/1130
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, currentSlide, slideCount, ...others } = props;
    return <span {...others}>{children}</span>;
  };
  const totalOptions = relatedArticles.length;
  const showArrows = totalOptions > 3 ? true : false;
  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3.3,
    className: cx("lg:grid-cols-1 lg:gap-6 lg:block lg:-mr-5"),
    arrows: showArrows,
    prevArrow: (
      <SlickButtonFix>
        <button onClick={previous} data-testid="prev-button">
          <img
            width={20}
            height={20}
            src={enabledArrow}
            alt="prev-arrow"
            className="-scale-x-100 w-10"
          />
        </button>
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <button onClick={next} data-testid="next-button">
          <img
            width={20}
            height={20}
            src={enabledArrow}
            alt="next-arrow"
            className="w-10"
          />
        </button>
      </SlickButtonFix>
    ),
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.5,
          swipeToSlide: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={rootClassName} data-testid={testId}>
      <Heading
        Element="h6"
        className="font-primary font-bold text-md mb-5 text-gray-100"
      >
        தொடர்புடைய செய்திகள்
      </Heading>
      <SliderComponent {...settings}>
        {relatedArticles.map((item) => {
          return (
            <ArticleCard
              key={item.id}
              displayWidth={235}
              displayHeight={157}
              overrideStyle={{
                cardWrapper:
                  "flex flex-col items-center justify-center w-full pr-xs pt-0 border-0",
                contentWrapper: "w-full",
                titleStyle:
                  "overflow-hidden overflow-ellipsis h-13.5 text-gray-100 hover:text-gray-100",
                imageWrapperStyle: "lg:w-[235px]",
              }}
              elements={{
                hasParagraph: false,
                hasDate: true,
              }}
              {...item}
            />
          );
        })}
      </SliderComponent>
    </div>
  );
}
