import "@app/styles/error-page.css";

import useRemoveOverlay from "@hooks/useRemoveOverlay";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

type Props = Readonly<{
  message?: string;
}>;

const DEFAULT_MESSAGE = "Server Error!";

export default function ErrorPage({
  message = DEFAULT_MESSAGE,
}: Props): ReactElement {
  useRemoveOverlay();
  return (
    <>
      <div className="error-page">
        <div id="container">
          <div className="white-container">
            <div className="text-center ml-12">
              {/* style="margin: 0 0 0 50px -3px; text-align: center;" */}
              <h1>
                <img
                  src="https://www.tamilmurasu.com.sg/_maintenance/tm_logo_desktop.png"
                  className="center img-responsive"
                  alt="Tamil Murasu"
                />
              </h1>
            </div>
            <p className="font-sans font-24">
              <strong>அன்புள்ள வாசகரே,</strong>
            </p>
            <p className="font-sans font-18" title={message}>
              எங்கள் சேவைகளில் தொழில்நுட்ப இடையூறு ஏற்பட்டுள்ளது. சேவைகளை
              மீண்டும் வழக்கநிலைக்குக் கொண்டுவர முயன்று வருகிறோம்.
              <br />
              இடைப்பட்ட வேளையில், தமிழ் முரசின் அண்மைய செய்திகளை கீழ்க்காணும்
              தளங்களில் காணலாம்: <br />
              <br />
              <Link
                to="https://www.facebook.com/tamilmurasu/"
                target="_blank"
                rel="noreferrer"
              >
                - ஃபேஸ்புக்{" "}
              </Link>
              <br />
              <Link
                to="https://www.instagram.com/tamil_murasu/"
                target="_blank"
                rel="noreferrer"
              >
                - இன்ஸ்டகிராம்{" "}
              </Link>
              <br />
              <Link
                to="https://www.youtube.com/@Tamil_Murasu"
                target="_blank"
                rel="noreferrer"
              >
                - யூடியூப்{" "}
              </Link>
              <br />
              <Link
                to="https://www.tiktok.com/@tamilmurasu_sg"
                target="_blank"
                rel="noreferrer"
              >
                - டிக்டாக்{" "}
              </Link>
              <br />
              <Link
                to="https://whatsapp.com/channel/0029VadVjPV8fewnqkhfPd2X"
                target="_blank"
                rel="noreferrer"
              >
                - வாட்ஸ்அப்{" "}
              </Link>
              <br />
              <Link
                to="https://t.me/tamilmurasu"
                target="_blank"
                rel="noreferrer"
              >
                - டெலிகிராம்{" "}
              </Link>
              <br />
              <br />
              அவசர உதவிக்கு <Link to="callto:63883838">6388-3838</Link> என்ற
              எண்ணில் எங்கள் வாடிக்கையாளர் சேவைக் குழுவை அல்லது{" "}
              <Link to="mailto:tamilmurasu@sph.com.sg">
                tamilmurasu@sph.com.sg
              </Link>{" "}
              என்ற மின்னஞ்சல் முகவரியில் தொடர்புகொள்ளலாம்.
              <br />
              பொது விடுமுறை நாள்கள் தவிர்த்து, திங்கள் முதல் வெள்ளி வரை காலை
              8.30 மணி முதல் மாலை 6 மணி வரையிலும் சனி, ஞாயிறுகளில் காலை 8.30 மணி
              முதல் நண்பகல் 12 மணி வரையிலும் எங்களது நேரடித் தொலைபேசிச் சேவை
              இயங்கும்.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
