import callIcon from "@assets/translation/call.png";
import emailIcon from "@assets/translation/email_icon.png";
import Image from "@components/ResponsiveImage/Image";
import { Link } from "react-router-dom";

export default function TranslationContactUs() {
  return (
    <div className="block text-center w-[95%] lg:w-[83%] h-[182px] lg:h-[190px] mx-auto bg-grey-1000">
      <div className="block font-secondary font-medium not-italic leading-950 lg:leading-10 text-2lg text-pink-700 mb-0 lg:mb-2 pt-2.5">
        Contact us
      </div>
      <div className="inline-grid lg:flex font-primary justify-center leading-250 lg:leading-675 font-normal text-base lg:text-1md">
        <div className="flex lg:border-r border-solid border-black-100 px-5 mx-auto lg:mx-0">
          Call us
          <Image
            srcWidth={0}
            srcHeight={0}
            objectFit="contain"
            src={callIcon}
            alt={"phone"}
            className="px-2"
          />
          :<div className="pl-2">(65) 6319 4062</div>
        </div>
        <div className="flex px-5 font-primary mx-auto lg:mx-0">
          Email
          <Image
            srcWidth={0}
            srcHeight={0}
            objectFit="contain"
            src={emailIcon}
            alt={"email"}
            className="px-2"
          />
          :
          <div className="pl-2">
            <Link to="mailto:cmuthu@sph.com.sg">cmuthu@sph.com.sg</Link>
          </div>
        </div>
      </div>
      <Link
        to="mailto:cmuthu@sph.com.sg"
        className="font-primary block rounded-150 bg-gradient-to-b from-pink-800 to-pink-700 w-[95%] mt-2.5 lg:mt-6 mb-5 lg:mb-lg mx-auto font-medium text-2sm lg:text-2lg leading-250 lg:leading-10 no-underline text-white-100"
      >
        <span className="hidden lg:block">Email us now</span>
        <span className="block lg:hidden pt-2 pb-xxs">Email us</span>
      </Link>
    </div>
  );
}
