import TmLogo from "@assets/tm_logo.webp";
import TmMobileLogo from "@assets/tm_logo_mobile.svg";
import desktopMainImage from "@assets/translation/desktop_Main_image.svg";
import mobileHeaderImage from "@assets/translation/Mobile_header_image.svg";
import Image from "@components/ResponsiveImage/Image";
import { HamburgerMenuIcon } from "@header/HamburgerMenu/HamburgerMenuIcon";
import useRemoveOverlay from "@hooks/useRemoveOverlay";
import TranslationClients from "@pages/Translation/TranslationClients";
import TranslationContactUs from "@pages/Translation/TranslationContactUs";
import TranslationSteps from "@pages/Translation/TranslationSteps";
import cx from "classnames";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function TranslationPage() {
  useRemoveOverlay();
  const [showMenuIcon, setShowMenuIcon] = useState(true);
  const [expandMenu, setExpandMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMenuIcon((prevShowMenuIcon) => !prevShowMenuIcon);
    setExpandMenu((prevExpandMenu) => !prevExpandMenu);
  };

  return (
    <div className="w-full">
      {/* Header */}
      <div className="lg:max-w-xl lg:mx-auto pt-sm lg:pt-0 pb-2.5 lg:pb-0 lg:mt-7 grid lg:block lg:text-center sticky z-50 top-0 lg:relative lg:z-0 bg-white-100 after:content-[''] lg:after:content-none after:relative after:w-full after:shadow-7xl after:z-50 after:top-xs">
        <div className="block px-sm lg:p-0">
          <div className="left-0 inline-flex lg:block absolute lg:relative w-1/2 lg:w-full bg-white-100 ">
            <div className="inline-flex m-auto">
              <Link to="/" rel="home" title="Tamil Murasu">
                <Image
                  srcWidth={295}
                  srcHeight={59}
                  alt="Home"
                  objectFit="contain"
                  src={TmLogo}
                  className="hidden lg:block !aspect-square h-15 w-[295px]"
                />
                <Image
                  srcWidth={172}
                  srcHeight={34}
                  alt="Home"
                  objectFit="contain"
                  src={TmMobileLogo}
                  className="lg:hidden block w-40 mr-1 h-xxm"
                />
              </Link>
            </div>
          </div>

          {/* Navigation Menu */}
          <div className="block lg:hidden float-right relative">
            <HamburgerMenuIcon
              showMenuIcon={showMenuIcon}
              expandMenu={expandMenu}
              onClick={toggleMobileMenu}
            />
          </div>
        </div>

        <nav
          className={cx(
            "lg:pt-2.5 lg:pb-100 text-center",
            !expandMenu
              ? "hidden lg:block"
              : "block lg:hidden top-sm relative z-10 w-full bg-white-100",
          )}
        >
          <div className="mx-auto block lg:flex lg:justify-center">
            <Link
              to="#overview"
              onClick={toggleMobileMenu}
              className="block lg:inline w-full lg:w-auto lg:mx-4 cursor-pointer font-primary not-italic font-medium lg:font-bold text-sm lg:text-xs leading-650 lg:leading-50 text-center text-pink-700 pt-[23px] pb-2.5 lg:py-2 px-200 mr-0 lg:mr-5 border-b border-solid border-gray-1100 lg:border-0 hover:border-b-2 hover:border-solid hover:border-pink-700 hover:pb-0"
            >
              Overview
            </Link>
            <Link
              to="#work_with_us"
              onClick={toggleMobileMenu}
              className="block lg:inline w-full lg:w-auto lg:mx-4 cursor-pointer font-primary not-italic font-medium lg:font-bold text-sm lg:text-xs leading-650 lg:leading-50 text-center text-pink-700 py-200 lg:py-2 px-200 mr-0 lg:mr-5 border-b border-solid border-gray-1100 lg:border-0 hover:border-b-2 hover:border-solid hover:border-pink-700 hover:pb-0"
            >
              Work with us
            </Link>
            <Link
              to="#our_clients"
              onClick={toggleMobileMenu}
              className="block lg:inline w-full lg:w-auto lg:mx-4 cursor-pointer font-primary not-italic font-medium lg:font-bold text-sm lg:text-xs leading-650 lg:leading-50 text-center text-pink-700 py-200 lg:py-2 px-200 mr-0 lg:mr-5 border-b border-solid border-gray-1100 lg:border-0 hover:border-b-2 hover:border-solid hover:border-pink-700 hover:pb-0"
            >
              Our clients
            </Link>
            <Link
              to="#about_us"
              onClick={toggleMobileMenu}
              className="block lg:inline w-full lg:w-auto lg:mx-4 cursor-pointer font-primary not-italic font-medium lg:font-bold text-sm lg:text-xs leading-650 lg:leading-50 text-center text-pink-700 pt-200 pb-5 lg:py-2 px-200 mr-0 lg:mr-5 border-b border-solid border-gray-1100 lg:border-0 hover:border-b-2 hover:border-solid hover:border-pink-700 hover:pb-0"
            >
              About us
            </Link>
          </div>
        </nav>
      </div>

      {/* Main content wrapper*/}
      <div id="overview">
        <div className="text-center">
          <Image
            srcWidth={0}
            srcHeight={0}
            objectFit="contain"
            src={desktopMainImage}
            className="hidden lg:block mr-1 w-full aspect-auto"
            alt={""}
          />
          <Image
            srcWidth={0}
            srcHeight={0}
            objectFit="contain"
            src={mobileHeaderImage}
            className="block lg:hidden mb-5 w-full"
            alt={""}
          />
          <span className="block lg:my-500 mx-auto w-full lg:w-3/4 px-200 lg:px-0 font-primary font-medium text-md lg:text-2lg leading-7 lg:leading-10 not-italic text-gray-200">
            Improve your productivity and avoid spending extra time & money{" "}
            <br />
            correcting unsatisfactory Tamil translations.
          </span>
          <div className="mb-lg mt-5 lg:mt-0 mx-sm lg:mx-0">
            <div className="block font-secondary not-italic leading-10 text-2md lg:text-2lg text-pink-700 mb-0 lg:mb-2.5">
              We provide:
            </div>
            <div className="w-full lg:w-10/12 block lg:flex mx-auto">
              <div className="pt-5 pb-3 lg:py-200 w-full lg:max-w-33 lg:flex-33">
                <div className="font-primary not-italic font-black text-2sm lg:text-sm leading-250 lg:leading-200 text-gray-200">
                  Experienced &<br className="hidden lg:block" /> Dedicated
                  Translators
                </div>
              </div>
              <div className="pt-5 pb-3 lg:py-200 w-full lg:max-w-33 lg:flex-33 border-y lg:border-y-0 lg:border-x border-solid border-gray-1100">
                <div className="font-primary not-italic font-black text-2sm lg:text-sm leading-250 lg:leading-200 text-gray-200">
                  Quick
                  <br className="hidden lg:block" /> Turn-around Time
                </div>
              </div>
              <div className="pt-5 pb-3 lg:py-200 w-full lg:max-w-33 lg:flex-33">
                <div className="font-primary not-italic font-black text-2sm lg:text-sm leading-250 lg:leading-200 text-gray-200">
                  Error-free
                  <br className="hidden lg:block" /> Translations
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Contact block */}
        <TranslationContactUs />

        <div className="pt-lg mx-3 lg:mx-0 lg:mt-500 lg:pt-0 text-center">
          <div className="block font-secondary font-medium not-italic leading-950 text-2lg text-pink-700 mb-sm">
            Is this you?
          </div>
          <div className="font-primary leading-230 lg:leading-6 text-2sm lg:text-sm text-gray-200">
            <div className="mb-4">
              Does your Tamil text appear gibberish once it is uploaded online?
            </div>
            <div className="mb-4">
              Are there errors in the work that you have received from other
              translation companies?
            </div>
            <div className="mb-4">
              Does your target audience have problems understanding the terms
              <br /> that you have used in your messaging?
            </div>
          </div>
          <div className="font-primary not-italic font-medium text-md lg:text-2lg leading-7 lg:leading-10 text-gray-200">
            Many businesses struggle with the same issues and we hear you!
            <br /> We are here to help!
          </div>
        </div>
      </div>

      <div id="work_with_us" className="pt-5 lg:pt-500 pb-xxs lg:pb-xm">
        <div className="mb-lg">
          <div className="mb-5 block font-secondary text-center not-italic leading-950 lg:leading-10 text-2md lg:text-2lg text-pink-700">
            Work with us in 3 simple steps!
          </div>

          {/* Steps block */}
          <TranslationSteps />
        </div>

        {/* Contact block */}
        <TranslationContactUs />
      </div>

      {/* Our Clients */}
      <div id="our_clients" className="py-xm">
        <div className="text-center">
          {/* Our clients logo block */}
          <TranslationClients />

          <div className="mx-2.5 lg:mx-lg my-lg lg:my-2.5 lg:m-0 font-primary not-italic font-normal text-2sm lg:text-sm leading-230 lg:leading-6 text-center text-black-200">
            <div className="mb-4">
              We understand that well-crafted sentences and words can have great
              meaning, this is why
              <br className="hidden lg:block" />
              our dedicated translation team at SPH are meticulous in keeping
              translations (English to Tamil) free
              <br className="hidden lg:block" />
              from errors with no distortion to the original intent.
            </div>
            <div className="mb-4">
              By working with us, you can ensure your intended messaging gets
              across to your target audience accurately.
            </div>
          </div>
        </div>

        {/* Contact block */}
        <TranslationContactUs />
      </div>

      {/* About Us */}
      <div className="pb-48 lg:pb-24 pt-xm text-center" id="about_us">
        <div className="block font-secondary not-italic leading-10 text-2lg text-pink-700 mb-2 pt-2.5">
          About Tamil Murasu
        </div>
        <div className="font-primary not-italic font-normal text-2sm lg:text-sm leading-230 lg:leading-6 text-black-200 mx-2.5 lg:mx-0 mb-5 lg:mb-4">
          Being the official and only vernacular Tamil Language paper in
          Singapore since 1935, we hold
          <br className="hidden lg:block" />
          ourselves to the highest Tamil translation service standards and with
          intimate knowledge of local
          <br className="hidden lg:block" />
          context and settings, will tailor copy to perfectly suit your target
          audience.
        </div>
        <div className="text-center mb-lg">
          <div className="mb-5 block font-secondary not-italic leading-10 text-2lg text-pink-700">
            Ready to work with our team of experienced translators?
          </div>

          {/* Steps block */}
          <TranslationSteps />
        </div>

        {/* Contact block */}
        <TranslationContactUs />
      </div>
    </div>
  );
}
