import {
  lb1AdSlotSizesInfinteScroll,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdSlotTargetingType,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

/**
 * TM Companion Lb Ad
 * Description: The Companion Lb component for rendering ads.
 * @name - name of section or page
 * @index - use when lb1 is rendered more than 1
 */
export function CompanionAdsLb({
  uniqueSectionName,
  index,
  pageAdTargetType,
  articleSlotTarget,
  rootClassName,
  gsChannels,
}: {
  uniqueSectionName?: string;
  index: number;
  pageAdTargetType: PageAdTargetValue;
  articleSlotTarget?: ArticleAdSlotTargetingType[];
  rootClassName?: string;
  gsChannels?: string[];
}) {
  let overrideLb1Slot = lb1AdSlotSizesInfinteScroll["desktop"];
  if (typeof window !== "undefined" && screen.width < 767) {
    overrideLb1Slot = lb1AdSlotSizesInfinteScroll["mobile"];
  }

  const pageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full",
        rootClassName,
      )}
    >
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(uniqueSectionName),
          id: `dfp-ad-lb1-${index}`,
          rootClassName: "m-0",
          adSlotSize: overrideLb1Slot,
          pageNumber: index.toString(),
          pubAdTargeting: [pageAdTarget],
          articleSlotTarget,
          gsChannels,
        }}
      />
    </div>
  );
}
