import downArrow from "@assets/actions/down-arrow.svg";
import DefaultImage from "@assets/tm_default_image.webp";
import GetReadingTime from "@elements/ArticleParagraph/GetReadingTime";
import { Image } from "@elements/ResponsiveImage/Image";
import ShareSocialButton from "@elements/ShareSocial/ShareSocialButton";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { getDateTime } from "@helper/useDateFormat";
import { isArrayWithElements } from "@helper/utils";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetValue } from "@typings/Ads.d";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
const socialIconList = ["whatsapp", "facebook", "x", "copylink"];

export type PhotoGalleryContentCardProps = {
  mainArticle: ProcessedArticleData;
  pageAdTargetValue: PageAdTargetValue;
  pageNumber: number;
};

export default function PhotoGalleryContentCard({
  mainArticle,
}: PhotoGalleryContentCardProps) {
  const { title, urlPath, dateCreated, dateEdited, articleBody, imageGallery } =
    mainArticle;

  const pageUrl = `${urlPath}`;

  // Get page reading time.
  const readingTime = GetReadingTime(articleBody, urlPath);

  const [isDateOpen, setDateIsOpen] = useState<boolean>(false);
  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setDateIsOpen((prev) => !prev);
  };

  return (
    <article>
      <Heading className="text-3md lg:text-lg font-bold font-primary">
        {title}
      </Heading>

      <div className="w-full inline-block py-xm">
        <div className="inline-block">
          <button
            onClick={toggleDropdown}
            className="dropdown-button py-2 flex"
          >
            <Date className="mr-2 text-gray-100">
              {getDateTime(dateCreated)}
            </Date>
            {dateEdited && (
              <img
                src={downArrow}
                alt={"dropdown-arrow"}
                width={10}
                height={10}
                className={twMerge("mt-2", isDateOpen && "-scale-y-100")}
              />
            )}
          </button>
          {isDateOpen && dateEdited && (
            <>
              <div className="mb-2 font-primary font-normal text-gray-800 text-xs lg:flex">
                <span className="mr-1">மாற்றம் செய்யப்பட்ட நாள்:</span>
                <Date className="mt-2 lg:mt-0">{getDateTime(dateEdited)}</Date>
              </div>
            </>
          )}

          <div className="font-primary font-medium text-gray-800 text-xs">
            {readingTime} mins read
          </div>
        </div>

        <div className="float-right">
          <ShareSocialButton
            socialIconList={socialIconList}
            pagePath={pageUrl}
            mbPopupClassName="absolute right-0"
            deskClassName="mb-5"
          />
        </div>
      </div>

      {isArrayWithElements(imageGallery) && (
        <div className="image-gallery">
          {imageGallery.map((galleryItem, i) => (
            <div key={galleryItem.id} className={i === 0 ? "mb-4" : "mb-1"}>
              {/* Image Component */}
              <Image
                displayWidth={800} // or use desired width
                srcWidth={800}
                srcHeight={450} // or use desired height
                src={galleryItem.image?.url ?? DefaultImage}
                alt={galleryItem.caption || title}
              />

              {/* Caption and Credit */}
              {galleryItem.caption && (
                <div className="inline-flex gap-2 bg-red-400 p-2 w-full">
                  <span className="w-full items-center left-0 text-gray-150 font-primary text-xs font-normal leading-250">
                    {galleryItem.caption} -{" "}
                    {galleryItem.credit && (
                      <span className="font-bold">{galleryItem.credit}</span>
                    )}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <div className="mt-4">
        <p className="mb-4">அனைத்து புகைப்படங்களும் காப்புரிமைக்கு உட்பட்டவை</p>
        <p className="mb-4">All photos copyrighted</p>
      </div>

      <ShareSocialButton
        socialIconList={socialIconList}
        pagePath={pageUrl}
        mbPopupClassName="mb-5"
        deskClassName="mb-5"
      />
    </article>
  );
}
