import { extractMonthAndYear } from "@helper/utils";
import { twMerge } from "tailwind-merge";

type issuesProps = {
  id: number;
  coverImageUri: string;
  readerUrl?: string;
  readerUrlWithPubs?: string;
  publishedDate: string;
  showHeadline?: boolean;
};

export default function BmCard({
  id,
  coverImageUri,
  readerUrlWithPubs,
  publishedDate,
  showHeadline = false,
}: issuesProps) {
  const { width, height } = showHeadline
    ? { width: 450, height: 500 }
    : { width: 240, height: 240 };

  const { month, year } = extractMonthAndYear(publishedDate);

  return (
    <div data-test-id={id} className="flex-20 text-center mb-2">
      {showHeadline && (
        <>
          <h1 className="pb-2 mb-2">
            <span className="pl-1 text-3md font-bold leading-10 max-lg:text-2md max-md:text-sm">
              இதழ் ({month} {year}){" "}
            </span>
          </h1>
        </>
      )}
      <div className="bm-more-issues-wrapper">
        <div className="">
          <a href={readerUrlWithPubs} target="_blank" rel="noreferrer">
            <img
              alt={`பாலர் முரசு இதழ் (${month} ${year})`}
              height={height}
              width={width}
              className={twMerge(
                showHeadline
                  ? "inline w-120 max-lg:h-125 max-md:h-100 max-lg:w-auto"
                  : "inline w-58 max-lg:w-52 max-md:w-45",
              )}
              src={coverImageUri}
            />
          </a>
        </div>
        {!showHeadline && (
          <div className="bg-slate-100 w-58 h-15 pt-2 max-lg:w-52 max-md:w-45 px-1">
            <a
              className="text-2sm font-bold leading-6 hover:underline"
              href={readerUrlWithPubs}
              target="_blank"
              rel="noreferrer"
            >
              பாலர் முரசு இதழ் ({month} {year}){" "}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
