import LinkIcon from "@elements/Icon/LinkIcon";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type authorProps = {
  destination: string;
  authorSrc: string;
  iconClassName?: string;
  authorName: string;
};

export default function Author({
  destination,
  authorSrc,
  iconClassName,
  authorName,
}: authorProps) {
  return (
    <div className="inline-flex text-red-100 font-primary font-semibold h-13.5 mb-5">
      {destination && authorSrc && (
        <LinkIcon
          destination={destination}
          src={authorSrc}
          alt="author"
          height="50px"
          width="50px"
          className={twMerge("p-1 mr-3.5 rounded-[50px]", iconClassName)}
        />
      )}
      <Link
        to={destination}
        className="text-red-100 text-2sm hover:text-red-200 hover:underline place-content-center"
      >
        {authorName} {" >"}
      </Link>
    </div>
  );
}
