import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import { BC_PLAYER_ID } from "@helper/getEnvVariables";

export type PodcastDialogProps = {
  videoDialog: {
    visible: boolean;
    source: string;
  };
  handleOnClickCloseDialog: () => void;
  playerId?: string;
};

export default function VideoDialog({
  videoDialog,
  handleOnClickCloseDialog,
  playerId = BC_PLAYER_ID,
}: PodcastDialogProps) {
  const { visible, source } = videoDialog;
  return (
    <>
      {visible && (
        <div className="fixed bottom-4 right-1 lg:right-4 lg:p-2 z-50 w-full lg:w-[520px]">
          <div className="relative">
            <BrightcoveVideo videoId={source} playerId={playerId} />
          </div>
          <div className="z-10 absolute top-4 right-4 lg:right-2.5 flex items-center justify-center w-8 h-8 p-1 rounded-full bg-black-500">
            <button
              onClick={handleOnClickCloseDialog}
              id="close"
              className="text-white-100 text-sm outline-none focus:outline-none"
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}
