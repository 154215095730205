import useRemoveOverlay from "@hooks/useRemoveOverlay";
import type { ReactElement } from "react";

export default function NotFound(): ReactElement {
  useRemoveOverlay();
  return (
    <div className="flex flex-col items-center mt-[150px]">
      <h1 className="text-md lg:text-lg">
        Sorry, the page you visited no longer exists.
      </h1>
      <p className="text-md lg:text-lg">
        Please visit our{" "}
        <span>
          <a href="/" className="text-red-100">
            Home Page
          </a>
        </span>
      </p>
    </div>
  );
}
