import cx from "classnames";

export type TagPropType = {
  value?: string;
  destination?: string;
  id?: string;
};

export default function Tags({ value, destination, id }: TagPropType) {
  return (
    <span
      data-testid={id}
      className={cx(
        "text-nowrap bg-white-200 text-gray-100 text-xs font-primary font-semibold leading-4 px-5 py-2.5 rounded-3xl border border-gray-1100 hover:bg-gray-2250",
        destination && "hover:underline",
      )}
    >
      {destination ? <a href={destination}>{value}</a> : `${value}`}
    </span>
  );
}

export type TagsProps = {
  tagsWrapper?: string;
  tags?: {
    alias?: string;
    name?: string;
  }[];
};
import { twMerge } from "tailwind-merge";

export const RenderTags = ({ tags, tagsWrapper }: TagsProps) => {
  return (
    Array.isArray(tags) && (
      <>
        <div className="font-primary text-md leading-650 font-bold mb-2.5 text-gray-100">
          குறிப்புச் சொற்கள்
        </div>
        <div
          className={twMerge("flex gap-xxs sm:gap-2 flex-wrap", tagsWrapper)}
          data-testid="tag-wrapper"
        >
          {tags.map(({ alias, name }, index) => (
            <Tags
              key={index}
              destination={alias}
              value={name}
              id={`tag-${index}`}
            />
          ))}
        </div>
      </>
    )
  );
};
