import BmLogo from "@assets/balarmurasu/Balar_Murasu_header_logo.svg";
import BilingualismLogo from "@assets/balarmurasu/bilingualism_logo.svg";
import cx from "classnames";
import type { ReactElement } from "react";

type BmLogoProp = {
  showMobile?: boolean;
};
export function HeaderBmLogo({ showMobile = false }: BmLogoProp): ReactElement {
  return (
    <>
      <a href="/balar-murasu" rel="balar_murasu" title="Balar Murasu">
        <img
          alt="பாலர் முரசு"
          src={BmLogo}
          className={cx(
            "w-auto h-16 mt-3",
            showMobile ? "block lg:hidden" : "hidden lg:block",
          )}
        />
      </a>
      <span
        className={cx(
          "border-l border-solid border-gray-400 mx-sm h-16 mt-3",
          showMobile ? "block lg:hidden" : "hidden lg:block",
        )}
      ></span>

      <a
        href="https://www.bilingualism.sg/"
        rel="balar_murasu noreferrer"
        target="_blank"
        title="Balar Murasu"
      >
        <img
          alt="Bilingualism"
          src={BilingualismLogo}
          className={cx(
            "w-auto h-20",
            showMobile ? "block lg:hidden" : "hidden lg:block",
          )}
        />
      </a>
    </>
  );
}
