/* eslint-disable jsx-a11y/click-events-have-key-events */
import UserMenuLogo from "@assets/user/Loggedin.svg";
import styles from "@header/TopHeader/UserInfo/UserInfo.module.css";
import { mySPHOpenLogout, mySPHOpenUpdatePwd } from "@helper/mySPH";
import type { UserInfoType } from "@hooks/useUserInfo";
import cx from "classnames";
import { MouseEvent, useEffect, useState } from "react";

export type UserInfoProps = {
  userInfo: UserInfoType;
};

/**
 *
 * @param rootClassName top-level css class override
 * @param userInfo user data decoded from JWT
 * @see https://docs.google.com/document/d/1obM9zIuU0Cj6UlI3mkHXGWDEIAGpCx029iiuV_kEwUU/edit# for API usage of window._mySPHObj
 */
export default function UserInfo({
  userInfo,
}: UserInfoProps): React.ReactElement {
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("click", () => {
      const userInfoPanelEl = document.getElementById("user-info-panel");
      if (userInfoPanelEl) {
        setIsPanelVisible(false);
      }
    });

    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);

  return (
    <div
      role="button"
      className={cx(styles.userInfo, "lg:px-3.5 lg:pt-2 cursor-pointer")}
      tabIndex={0}
      onKeyUp={() => {}}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsPanelVisible(!isPanelVisible);
      }}
    >
      <div className="inline-flex text-black font-bold text-xxs">
        <img src={UserMenuLogo} className="mr-2 h-5" alt="login" />
        {userInfo ? (
          <>
            <span
              id="sph_user_name_hamburger"
              className={cx(
                styles.userInfoLoginHamburger,
                "block lg:hidden text-red-100 mr-1",
              )}
            >
              {userInfo.loginId}
            </span>
            <span id="sph_user_name" className="hidden">
              {userInfo.loginId}
            </span>
          </>
        ) : null}
        <div
          className={cx(
            // isPanelVisible ? styles.open : "",
            styles.dropdownToggle,
            "hover:text-grey-800 text-[12px] text-grey-800 font-semibold",
          )}
        ></div>
      </div>

      {isPanelVisible ? (
        <ul id="user-info-panel" className={styles.userInfoPanel}>
          {userInfo ? (
            <li className={"mt-xxs hidden lg:block"}>
              <span className={styles.userInfoLoginId}>{userInfo.loginId}</span>
            </li>
          ) : null}
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions*/}
          <li
            className="hover:underline decoration-1 text-xs"
            onClick={mySPHOpenUpdatePwd}
          >
            மறைச்சொல்லை மாற்ற
          </li>
          <div className={styles.mySPHLine}></div>
          {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions*/}
          <li
            className="font-semibold text-center hover:underline decoration-1 text-xs"
            onClick={mySPHOpenLogout}
          >
            வெளியே
          </li>
        </ul>
      ) : null}
    </div>
  );
}
