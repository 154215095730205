import { ENVIRONMENT } from "@helper/getEnvVariables";

export const isArrayWithElements = (payload: unknown): payload is unknown[] => {
  return Array.isArray(payload) && payload.length >= 1;
};

export const getCookieByName = (name: string): string | undefined => {
  if (typeof window === "undefined") {
    return;
  }
  const match = document.cookie.match(new RegExp(name + "=([^;]+)"));
  return match ? match[1] : undefined;
};

export const isSameOrigin = (origin: string): boolean => {
  if (origin === "http://localhost:3000") return true;
  const matches = origin.match(/([^\\/.]+)\.com.sg/);
  if (matches && matches.length > 1) {
    return matches[1] === "tamilmurasu";
  }
  return false;
};

export const sanitizeSearchKeyword = (searchValue: string) => {
  const sanitizedInput = searchValue.replace(/[^\w\s]/gi, "").substring(0, 255);
  return sanitizedInput;
};

export const prefixCheck = (url: string): boolean => {
  if (url.startsWith("/") && !url.includes("://")) {
    return true;
  }
  return false;
};

export const getWindowOrigin = (): string => {
  if (typeof window !== "undefined") {
    return window.location.origin;
  }
  return getEnvOrigin();
};

export const getEnvOrigin = (): string => {
  switch (ENVIRONMENT) {
    case "dev":
      return "https://web2-dev.tamilmurasu.com.sg";
    case "stg":
      return "https://uat.tamilmurasu.com.sg";
    case "prd":
      return "https://www.tamilmurasu.com.sg";
    default:
      return "http://localhost:3000";
  }
};

//iteration determine how frequent it would return true, useful for ads.
export const displayAfter = (index: number, iteration: number): boolean => {
  return index > 0 && index % iteration === 0;
};

//iteration determine how frequent it would return true, useful for ads.
export const hideAfter = (index: number, iteration: number): boolean => {
  return index > 0 && index % iteration != 0;
};

export const isClientSide: boolean = typeof window !== "undefined";
export const isProd = "https://www.tamilmurasu.com.sg/" === getWindowOrigin();

/**
 * Description of the function.
 *
 * @param {Type} callback - Callback for this function.
 * @param {Type} maxRetries - No. of tries wanted to do.
 * @param {Type} initialDelayMs - Initial delay for each retry.
 * @param {Type} maxDelayMs - Maximum allowed delay.
 */

export async function fetchWithRetry<T>(
  callback: () => Promise<T>,
  maxRetries: number,
  initialDelayMs: number,
  maxDelayMs: number,
): Promise<T> {
  let retries = 0;
  let delay = initialDelayMs;

  while (retries < maxRetries) {
    try {
      return await callback();
    } catch (error) {
      retries++;
      if (retries >= maxRetries) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
      delay = Math.min(delay * 2, maxDelayMs);
    }
  }

  throw new Error("Max retries exceeded");
}

export function getValidEmailRegex() {
  return /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
}

export const getLevel1Route = (url: string) => {
  const path = new URL(url).pathname;
  const pathParts = path.split("/");
  const filteredPathParts = pathParts.filter((part) => part.trim() !== "");

  if (filteredPathParts.length > 0) {
    return filteredPathParts[0];
  } else {
    return null;
  }
};

export function isKeyExistsInEnum(CacheKey: string, enumObj: object): boolean {
  return Object.values(enumObj).includes(CacheKey);
}

/**
 * Helper function to check if URL is a URL from the specified from parameter.
 * @param url URL to check.
 * @returns {boolean}
 */
export const isThisUrlFrom = (url: string, from: string): boolean => {
  const domain = new URL(url).hostname;
  return domain.includes(from);
};

export function mapArticlesOrder<T>(
  array: T[],
  order: string[],
  key: keyof T,
): T[] {
  array.sort((a, b) => {
    const A = a[key] as unknown as string;
    const B = b[key] as unknown as string;
    return order.indexOf(A) - order.indexOf(B);
  });
  return array;
}

export const extractMonthAndYear = (
  dateString: string,
): { month: string; year: number } => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid date string");
  }
  const year = date.getFullYear();
  const month = new Intl.DateTimeFormat("ta-IN", { month: "long" }).format(
    date,
  );

  return { month, year };
};
