import "@app/styles/index.css";

import styles from "@app/App.module.css";
import HeadScriptProvider from "@app/providers/HeadScriptProvider";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { RenderContext } from "@sphtech/web2-core/ssr";
import NeuronProvider from "@web2/providers/NeuronProvider";
import NewRelic from "@web2/providers/NewRelic";
import { ReactElement, StrictMode, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);
  return (
    <StrictMode>
      <HeadScriptProvider />
      <NeuronProvider>
        <ScrollRestoration />
        <HelmetProvider context={renderContext.helmet}>
          <GTMProvider state={{ id: "GTM-KNCGB6N" }}>
            <NewRelic />
            <div id="MyPageOverlay" className={styles.overlayWhite}></div>
            <div id="postitial_holder"></div>
            <Outlet />
          </GTMProvider>
        </HelmetProvider>
      </NeuronProvider>
    </StrictMode>
  );
}
