import TmMobileLogo from "@assets/tm_logo_mobile.svg";
import { HeaderBmLogo } from "@header/BalarMurasu/HeaderBmLogo";
import { HamburgerMenuIcon } from "@header/HamburgerMenu/HamburgerMenuIcon";
import { HamburgerMenuModal } from "@header/HamburgerMenu/HamburgerMenuModel";
import { HeaderSubscribe } from "@header/HeaderSubscribe";
import { SearchButton } from "@header/Search/SearchButton";
import { SearchInput } from "@header/Search/SearchInput";
import { TopHeader } from "@header/TopHeader/TopHeader";
import { useState } from "react";

export type BmHeaderProps = {
  testId: string;
};

export function BmHeader({ testId = "bm-header" }: BmHeaderProps) {
  const [showMenuIcon, setShowMenuIcon] = useState(true);
  const [expandMenu, setExpandMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMenuIcon((prevShowMenuIcon) => !prevShowMenuIcon);
    setExpandMenu((prevExpandMenu) => !prevExpandMenu);
  };

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <div className="xs:sticky lg:relative ">
      <TopHeader section="balar-murasu" />
      <div
        data-testid={`${testId}-headerContainer`}
        className="z-0 w-full bg-white-100 relative"
      >
        <div className="xl:max-w-xl lg:max-w-lg md:max-w-xm sm:max-w-xs w-full mx-auto lg:h-24 lg:grid lg:grid-cols-6 max-lg:px-3.5 max-lg:pt-3.5 max-lg:pb-1">
          <div className="inline-flex m-0 lg:col-span-2 lg:col-start-3 lg:col-end-6 xs:h-lg md:max-w-xs">
            <HeaderBmLogo />
            <a
              data-testid={`${testId}-logo`}
              href="/"
              rel="home"
              title="Tamil Murasu"
            >
              <img
                width={144}
                height={29.81}
                alt="Home"
                src={TmMobileLogo}
                className="lg:hidden block w-36 mr-1"
              />
            </a>
          </div>

          <div className="m-auto hidden lg:flex h-11 py-4xs">
            <HeaderSubscribe />
            <span className="border-l border-solid border-gray-400 ml-5"></span>
            <SearchButton onClick={toggleExpanded} />
          </div>

          <div
            data-testid={`${testId}-hamburger-menu-container`}
            className="inline-flex float-right lg:hidden"
          >
            <SearchButton onClick={toggleExpanded} />
            <span className="border-l border-solid border-gray-400 inline lg:hidden" />
            <HamburgerMenuIcon
              showMenuIcon={showMenuIcon}
              expandMenu={expandMenu}
              onClick={toggleMobileMenu}
            />
          </div>
        </div>

        <SearchInput
          expanded={expanded}
          className="lg:max-w-xl mx-auto px-sm pb-4 bg-white-100"
        />

        <HamburgerMenuModal
          expandMenu={expandMenu}
          onClick={toggleMobileMenu}
        />
      </div>
    </div>
  );
}
