import ChevronImage from "@assets/video_widget/Chevron.webp";
import ArticleCard from "@components/Card/ArticleCard";
import Heading from "@elements/Typography/Heading";
import { ProcessedArticleMapList } from "@helper/transformer/useOsResponse";
import useSwrApi from "@hooks/useSwrApi";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { Link } from "react-router-dom";

export const CinemaArticles = () => {
  const { data: cinemaArticlesMap } = useSwrApi<ProcessedArticleMapList>(
    `latest-section-articles?size=3&section=cinema`,
  );
  const cinemaArticlesList = cinemaArticlesMap
    ? Object.keys(cinemaArticlesMap).map(
        (mapKey: string) => cinemaArticlesMap[mapKey],
      )
    : [];
  const { width } = useWindowDimensions();
  const isMobile = width <= 480;
  return (
    <div>
      <Heading
        Element="h2"
        className="text-red-100 text-2.5md font-bold hover:underline hover:cursor-pointer hover:text-red-200 mt-5"
      >
        <Link reloadDocument to={`/cinema`} target="_blank">
          திரைச்செய்தி
          <img
            alt="toggle-button-chevie"
            height={26}
            width={26}
            className="pl-100 inline"
            src={ChevronImage}
          />
        </Link>
      </Heading>
      {cinemaArticlesList.map((article, i, row) => (
        <ArticleCard
          key={article.id}
          displayWidth={345}
          displayHeight={230}
          elements={{
            hasParagraph: isMobile ? (i === 0 ? true : false) : true,
            hasDate: true,
          }}
          {...article}
          overrideStyle={{
            cardWrapper:
              i + 1 === row.length
                ? "w-full lg:w-full flex-row-reverse lg:flex-row border-b-0"
                : isMobile && i === 0
                  ? "flex-col"
                  : "w-full lg:w-full flex-row-reverse lg:flex-row",
            paragraphStyle: "lg:text-2sm",
            titleStyle:
              "font-semibold font-primary text-sm2 text-gray-100 hover:text-gray-100",
            imageWrapperStyle:
              isMobile && i === 0
                ? "w-full max-w-full lg:w-full h-55 lg:h-130 lg:max-h-130"
                : "w-23 max-w-60 lg:w-60 h-23 lg:h-40 sm:h-32",
            titleWrapper: "gap-0",
            imageStyle: isMobile && i === 0 ? "h-55" : "h-23 lg:h-40 sm:h-32",
          }}
        />
      ))}
    </div>
  );
};
