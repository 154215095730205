import AEDImage from "@assets/currency/AED.webp";
import AUDImage from "@assets/currency/AUD.webp";
import GBPImage from "@assets/currency/GBP.webp";
import IDRImage from "@assets/currency/IDR.webp";
import INRImage from "@assets/currency/INR.webp";
import LKRImage from "@assets/currency/LKR.webp";
import MYRImage from "@assets/currency/MYR.webp";
import USDImage from "@assets/currency/USD.webp";
import { CurrencyCard } from "@container/SideBlock/CurrencyCard";
import Heading from "@elements/Typography/Heading";
import { ForexPairDrupalResponse } from "@endpoints/getForexPairs";
import to2dp from "@helper/math/to2dp";
import { formatTime, fullDate } from "@helper/useDateFormat";
import useSwrApi from "@hooks/useSwrApi";

function currencyPairImageMapper(currencyCode: string) {
  switch (currencyCode) {
    case "USD":
      return USDImage;
    case "AUD":
      return AUDImage;
    case "INR":
      return INRImage;
    case "IDR":
      return IDRImage;
    case "MYR":
      return MYRImage;
    case "LKR":
      return LKRImage;
    case "AED":
      return AEDImage;
    case "GBP":
      return GBPImage;
    default:
      return USDImage;
  }
}

export const ForexSection = () => {
  const { data: drupalResponse } =
    useSwrApi<ForexPairDrupalResponse>("forex-pairs");
  const data = drupalResponse?.result;
  const date = drupalResponse?.date;
  return (
    <div>
      <Heading
        Element="h2"
        className="text-gray-100 text-md font-bold lg:mt-5 mb-5"
      >
        நாணய பரிவர்த்தனை
      </Heading>
      <p className="text-grey-700 text-base font-normal">
        ஒரு சிங்கப்பூர் வெள்ளிக்கு
      </p>
      {data && (
        <div className="flex flex-wrap">
          {data.inr && (
            <CurrencyCard
              key={data.inr.currency_code}
              symbol={data.inr.currency_code}
              imgUrl={currencyPairImageMapper(data.inr.currency_code)}
              priceChange={to2dp(
                ((Number(data["inr"].today_rate) -
                  Number(data["inr"].yesterday_rate)) /
                  Number(data["inr"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.inr.today_rate}
            />
          )}
          {data.myr && (
            <CurrencyCard
              key={data.myr.currency_code}
              symbol={data.myr.currency_code}
              imgUrl={currencyPairImageMapper(data.myr.currency_code)}
              priceChange={to2dp(
                ((Number(data["myr"].today_rate) -
                  Number(data["myr"].yesterday_rate)) /
                  Number(data["myr"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.myr.today_rate}
            />
          )}
          {data.lkr && (
            <CurrencyCard
              key={data.lkr.currency_code}
              symbol={data.lkr.currency_code}
              imgUrl={currencyPairImageMapper(data.lkr.currency_code)}
              priceChange={to2dp(
                ((Number(data["lkr"].today_rate) -
                  Number(data["lkr"].yesterday_rate)) /
                  Number(data["lkr"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.lkr.today_rate}
            />
          )}
          {data.aed && (
            <CurrencyCard
              key={data.aed.currency_code}
              symbol={data.aed.currency_code}
              imgUrl={currencyPairImageMapper(data.aed.currency_code)}
              priceChange={to2dp(
                ((Number(data["aed"].today_rate) -
                  Number(data["aed"].yesterday_rate)) /
                  Number(data["aed"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.aed.today_rate}
            />
          )}
          {data.usd && (
            <CurrencyCard
              key={data.usd.currency_code}
              symbol={data.usd.currency_code}
              imgUrl={currencyPairImageMapper(data.usd.currency_code)}
              priceChange={to2dp(
                ((Number(data["usd"].today_rate) -
                  Number(data["usd"].yesterday_rate)) /
                  Number(data["usd"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.usd.today_rate}
            />
          )}
          {data.aud && (
            <CurrencyCard
              key={data.aud.currency_code}
              symbol={data.aud.currency_code}
              imgUrl={currencyPairImageMapper(data.aud.currency_code)}
              priceChange={to2dp(
                ((Number(data["aud"].today_rate) -
                  Number(data["aud"].yesterday_rate)) /
                  Number(data["aud"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.aud.today_rate}
            />
          )}
          {data.gbp && (
            <CurrencyCard
              key={data.gbp.currency_code}
              symbol={data.gbp.currency_code}
              imgUrl={currencyPairImageMapper(data.gbp.currency_code)}
              priceChange={to2dp(
                ((Number(data["gbp"].today_rate) -
                  Number(data["gbp"].yesterday_rate)) /
                  Number(data["gbp"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.gbp.today_rate}
            />
          )}
          {data.idr && (
            <CurrencyCard
              key={data.idr.currency_code}
              symbol={data.idr.currency_code}
              imgUrl={currencyPairImageMapper(data.idr.currency_code)}
              priceChange={to2dp(
                ((Number(data["idr"].today_rate) -
                  Number(data["idr"].yesterday_rate)) /
                  Number(data["idr"].yesterday_rate)) *
                  100,
              )}
              currentPrice={data.idr.today_rate}
            />
          )}
        </div>
      )}
      {date && (
        <div className="font-primary font-medium text-gray-150 text-xs mt-[30px]">
          Updated on {fullDate(date)} - {formatTime(date)}
        </div>
      )}
    </div>
  );
};
