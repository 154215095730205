import {
  type ProcessedArticleBody,
  type ProcessedFullParagraph,
} from "@typings/Opensearch";

const EN_TIME = 200;
const TA_TIME = 150;

export default function GetReadingTime(
  articleBody: ProcessedArticleBody,
  urlPath?: string,
): number {
  const paraWordCount = articleBody
    .filter((element): element is ProcessedFullParagraph => {
      return element.type === "paragraph";
    })
    .map((element) => {
      const length = element.value ? element.value.split(" ").length : 0;
      return length;
    });
  const wordCount = paraWordCount.reduce(
    (total, currentValue) => (total = total + currentValue),
    0,
  );

  let estimatedReadingTime = 0;

  if (urlPath && urlPath.includes("/tabla")) {
    // Returns en time
    if (wordCount <= EN_TIME) {
      estimatedReadingTime = 1;
    } else if (wordCount <= EN_TIME + EN_TIME) {
      estimatedReadingTime = 2;
    } else {
      const additionalWordCount = wordCount - (EN_TIME + EN_TIME);
      const additionalReadingTime = Math.ceil(additionalWordCount / EN_TIME);
      estimatedReadingTime = 2 + additionalReadingTime;
    }
  } else {
    // Returns ta time
    if (wordCount <= TA_TIME) {
      estimatedReadingTime = 1;
    } else if (wordCount <= TA_TIME + TA_TIME) {
      estimatedReadingTime = 2;
    } else {
      const additionalWordCount = wordCount - (TA_TIME + TA_TIME);
      const additionalReadingTime = Math.ceil(additionalWordCount / TA_TIME);
      estimatedReadingTime = 2 + additionalReadingTime;
    }
  }

  return estimatedReadingTime;
}
