import cpfLogo from "@assets/translation/clients/CPF_Logo.svg";
import marsilingYewTeeLogo from "@assets/translation/clients/Marsiling_Yew_Tee_logo.svg";
import nkfLogo from "@assets/translation/clients/NKF_Logo.svg";
import paLogo from "@assets/translation/clients/PA_Logo_2015.svg";
import sbstLogo from "@assets/translation/clients/SBST_logo.svg";
import wsgLogo from "@assets/translation/clients/WSG_logo.svg";
import Image from "@components/ResponsiveImage/Image";

export default function TranslationClients() {
  return (
    <>
      <div className="mb-5 block font-secondary not-italic leading-10 text-2lg text-pink-700">
        Our Clients
      </div>
      <div className="w-full lg:w-[550px] mx-auto">
        <div className="mb-5 flex lg:-mx-2.5 justify-center">
          <div className="lg:flex-33 max-w-33 w-27 h-22 lg:w-32 lg:h-29">
            <Image
              srcWidth={150}
              srcHeight={120}
              objectFit="contain"
              src={nkfLogo}
              alt={"nkf"}
              className="w-[100px] h-15 mt-3 lg:w-[140px] lg:h-21 lg:mt-4"
            />
          </div>
          <div className="lg:flex-33 max-w-33 w-27 h-22 lg:w-32 lg:h-29 mx-5 lg:mx-0">
            <Image
              srcWidth={150}
              srcHeight={120}
              objectFit="contain"
              src={wsgLogo}
              alt={"wfg"}
              className="w-[107px] h-[58px] mt-3 lg:w-32 lg:h-[82px] lg:mt-[19px]"
            />
          </div>
          <div className="lg:flex-33 max-w-33 w-27 h-22 lg:w-32 lg:h-29">
            <Image
              srcWidth={150}
              srcHeight={120}
              objectFit="contain"
              src={sbstLogo}
              alt={"sbst"}
              className="w-[102px] h-6 mt-lg lg:w-[143px] lg:h-[34px] lg:mt-[43px]"
            />
          </div>
        </div>
        <div className="mb-5 flex lg:-mx-2.5 justify-center">
          <div className="lg:flex-33 max-w-33 w-27 h-22 lg:w-32 lg:h-29">
            <Image
              srcWidth={150}
              srcHeight={120}
              objectFit="contain"
              src={cpfLogo}
              alt={"cpf"}
              className="w-[108px] h-[51px] mt-3 lg:w-32 lg:h-[72px] lg:mt-sm"
            />
          </div>
          <div className="lg:flex-33 max-w-33 w-27 h-22 lg:w-32 lg:h-29">
            <Image
              srcWidth={150}
              srcHeight={120}
              objectFit="contain"
              src={paLogo}
              alt={"pa"}
              className="w-[67px] h-15 mt-3 mx-auto lg:w-[92px] lg:h-[82px] lg:mt-[19px]"
            />
          </div>
          <div className="lg:flex-33 max-w-33 w-27 h-22 lg:w-32 lg:h-29">
            <Image
              srcWidth={150}
              srcHeight={120}
              objectFit="contain"
              src={marsilingYewTeeLogo}
              alt={"marsilin_yew_tee"}
              className="w-[107px] h-lg mt-7 lg:w-32 lg:h-9 lg:mt-[39px]"
            />
          </div>
        </div>
      </div>
    </>
  );
}
