import ChevronImage from "@assets/video_widget/Chevron.webp";
import TranslateBanner from "@blocks/Home/TranslateBanner";
import PromoteEpaper from "@blocks/PromoteEpaper/PromoteEpaper";
import ArticleCard from "@components/Card/ArticleCard";
import { ForexSection } from "@container/SideBlock/ForexSection";
import { GoldSection } from "@container/SideBlock/GoldSection";
import MostRead from "@container/SideBlock/MostRead";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import Divider from "@elements/Divider/Divider";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import Heading from "@elements/Typography/Heading";
import { isArrayWithElements } from "@helper/utils";
import useSwrApi from "@hooks/useSwrApi";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { ArticleAdPageTargetingType, PageAdTargetValue } from "@typings/Ads.d";
import { SideBlockArticleResponse } from "@typings/APIResponse";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type SideBlockProps = {
  className?: string;
  sectionName?: string;
  pageAdTargetValue: PageAdTargetValue;
  promoteEpaper?: boolean;
  hasGold?: boolean;
  hasForex?: boolean;
  hasOutbrain?: boolean;
  hasTranslate?: boolean;
  hideAdForMobile?: boolean;
  articlePageTarget?: ArticleAdPageTargetingType[];
  gsChannels?: string[];
};

export default function SideBlock({
  className,
  sectionName,
  hasOutbrain,
  hasGold,
  hasForex,
  pageAdTargetValue,
  promoteEpaper,
  hasTranslate,
  hideAdForMobile = false,
  articlePageTarget,
  gsChannels,
}: SideBlockProps) {
  const { data } = useSwrApi<SideBlockArticleResponse>("side-block-articles");
  const { EditorialList, SocialList } = data || {};
  const { isScreenLG } = useWindowSize();

  const SectionArticleConfig = {
    elements: { hasParagraph: false, hasDate: true },
    overrideStyle: {
      cardWrapper: "flex-row flex-row-reverse",
      titleStyle:
        "font-semibold font-primary text-2sm text-gray-100 hover:text-gray-100",
      imageWrapperStyle:
        "max-w-[175px] lg:w-[175px] lg:h-[90px] lg:max-h-[90px]",
      contentWrapper: "gap-xs",
      titleWrapper: "gap-0",
      imageStyle: "h-[90px]",
    },
  };

  return (
    <div className={twMerge("lg:w-4/12 lg:pr-xxs lg:pl-md min-h-[800px]")}>
      <div data-testid="side-block" className={twMerge("w-full", className)}>
        {/* Promote epaper block */}
        {promoteEpaper && <PromoteEpaper />}

        {/* Advertisement */}
        {(isScreenLG || !hideAdForMobile) && (
          <>
            <AdsImu2
              sectionName={sectionName}
              pageAdTargetType={pageAdTargetValue}
              articlePageTarget={articlePageTarget}
              gsChannels={gsChannels}
            />
            <Divider className="border-b border-gray-2200" />
          </>
        )}

        {hasOutbrain && (
          <>
            <Outbrain pathName="/" version="SB_1" />
            <Divider className="mt-5 border-b border-gray-2200" />
          </>
        )}

        {hasGold && (
          <>
            <GoldSection />
            <Divider className="mt-5 border-b border-gray-2200" />
          </>
        )}

        {hasForex && (
          <>
            <ForexSection />
            <Divider className="mt-5 border-b border-gray-2200" />
          </>
        )}
        <div className="w-full border-b border-gray-2200">
          {isArrayWithElements(SocialList) ? (
            <>
              <Heading
                Element="h2"
                className="text-red-100 text-md font-bold hover:underline hover:cursor-pointer hover:text-red-200 mt-5"
              >
                <Link reloadDocument to={"/community"} target="_blank">
                  சமூகம்
                  <img
                    alt="toggle-button-chevie"
                    height={26}
                    width={26}
                    className="pl-100 inline"
                    src={ChevronImage}
                  />
                </Link>
              </Heading>
              {SocialList.map((section, i, row) => (
                <ArticleCard
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  key={section.id}
                  displayWidth={175}
                  displayHeight={90}
                  {...SectionArticleConfig}
                  {...section}
                  overrideStyle={{
                    cardWrapper:
                      i + 1 === row.length
                        ? "w-full lg:w-full flex-row-reverse pb-5 border-0"
                        : "w-full lg:w-full flex-row-reverse pb-5",
                    titleStyle:
                      "font-semibold font-primary text-sm2 text-gray-100 hover:text-gray-100",
                    imageWrapperStyle: "w-40 max-w-44 lg:w-40 h-23 max-sm:w-23",
                    imageStyle: "w-full h-23",
                  }}
                />
              ))}
            </>
          ) : null}
        </div>
        {/* <Divider className="mt-5 border-b border-gray-2200" /> */}
        <MostRead />
        <div className="w-full border-b border-gray-2200">
          {isArrayWithElements(EditorialList) ? (
            <>
              <Heading
                Element="h2"
                className="text-red-100 text-md font-bold hover:underline hover:cursor-pointer hover:text-red-200 mt-5"
              >
                <Link reloadDocument to={"/editorial"} target="_blank">
                  தலையங்கம்
                  <img
                    alt="toggle-button-chevie"
                    height={26}
                    width={26}
                    className="pl-100 inline"
                    src={ChevronImage}
                  />
                </Link>
              </Heading>
              {EditorialList.map((section, i, row) => (
                <ArticleCard
                  key={section.id}
                  displayWidth={175}
                  displayHeight={90}
                  {...SectionArticleConfig}
                  {...section}
                  overrideStyle={{
                    cardWrapper:
                      i + 1 === row.length
                        ? "w-full lg:w-full flex-row-reverse pb-5 border-0"
                        : "w-full lg:w-full flex-row-reverse pb-5",
                    titleStyle:
                      "font-semibold font-primary text-sm2 text-gray-100 hover:text-gray-100",
                    imageWrapperStyle: "w-40 max-w-44 lg:w-40 h-23 max-sm:w-23",
                    imageStyle: "w-full h-23",
                  }}
                />
              ))}
            </>
          ) : null}
        </div>

        {hasTranslate && (
          <TranslateBanner mobileView={!isScreenLG} className="mt-5" />
        )}
      </div>
    </div>
  );
}
