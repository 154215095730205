import "@elements/ArticleParagraph/Embeds/Embeds.css";

import { SOCIAL_EMBED_WIDTH } from "@elements/ArticleParagraph/Embeds/constants";
import WrapperContainer from "@elements/ArticleParagraph/ParagraphElements/WrapperContainer";
import { FB_APP_ID } from "@elements/MetaTags/MetaTagsConstants";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { EmbeddedPost, FacebookProvider } from "react-facebook";

export type FacebookEmbedCustomProps = {
  url: string;
  className?: string;
};

export default function FacebookEmbedCustom({
  url,
  className = "",
}: FacebookEmbedCustomProps): React.ReactElement {
  const { isScreenSM } = useWindowSize();

  return (
    <FacebookProvider appId={FB_APP_ID}>
      <WrapperContainer className={className}>
        <EmbeddedPost
          href={url}
          width={isScreenSM ? SOCIAL_EMBED_WIDTH : "auto"}
          lazy
          showText
        />
      </WrapperContainer>
    </FacebookProvider>
  );
}
