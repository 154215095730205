import { AdUnit } from "@elements/Advertisement/AdUnit";
import { useAdvertLoaded } from "@elements/Advertisement/helpers";
import { AdUnitProps } from "@typings/Ads.d";
import cx from "classnames";
import { ReactElement } from "react";

export type AdvertisementProps = {
  rootClassName?: string;
  adUnitProps: AdUnitProps;
};

/**
 * This component renders an ad.
 * @param rootClassName - The root class name.
 * @param type - The type of the ad.
 * @param adUnitProps - The props for the ad.
 * @see https://www.imsph.sg/advertising-solutions/digital/imu/
 * @returns
 */
export default function Advertisement({
  rootClassName = "",
  adUnitProps,
}: AdvertisementProps): ReactElement {
  const advertLoaded = useAdvertLoaded(adUnitProps.type, adUnitProps.id);

  return (
    <div
      className={cx(!advertLoaded && "invisible", rootClassName)}
      style={{
        minHeight: "50px",
      }}
      data-testid="advertisement-element"
    >
      <AdUnit {...adUnitProps} />
    </div>
  );
}
