import { TopHeaderMenues } from "@header/TopHeader/TopHeaderMenues";
import { SocialMedia } from "@header/TopHeader/TopSocialMedia";
import type { ReactElement } from "react";

export type TopHeaderProps = {
  testId?: string;
  section?: string;
};

export function TopHeader({
  testId = "topheader",
  section = "",
}: TopHeaderProps): ReactElement {
  return (
    <div className="w-full bg-gray-500 hidden lg:block">
      <div className="lg:max-w-lg xl:max-w-xl w-full mx-auto px-sm h-xxm">
        <div className="inline-block mt-1">
          <SocialMedia testId={testId} />
        </div>
        <div className="float-right px-sm inline-flex font-primary">
          <TopHeaderMenues section={section} />
        </div>
      </div>
    </div>
  );
}
